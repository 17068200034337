import { React, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth } from "./Authentication/AuthContext";
import api from "../api/axiosConfig.js";
import { ProjectContext } from "../App.js";
import StatusPieChart from "./Home/ProjectStatusPie.js";
import ProjectManagerBarChart from "./Home/ProjectManager.js";
import ProjectCustomerPieChart from "./Home/ProjectCustomerPieChart.js";
import ProjectCustomerBarChart from "./Home/ProjectCustomerBarchart.js";
import ProjectTimelineSummary from "./Home/ProjectTimelineSummary.js";
import GroupPieChart from "./Home/GroupsPieChart.js";
import TimesheetPieChart from "../components/Dashboard/TimesheetPieChart.js";
import TimesheetCalendar from "../components/Dashboard/TimesheetCalendar.js";
import ProjectPhasePieChart from "../components/Dashboard/ProjectPhasePieChart.js";
import TimesheetDurationBillableNonBillable from "../components/Dashboard/TimesheetDurationBillableNonBillable.js";
import "../styles/projects/Dashboard.css";
import "../styles/Home.css";

const fetchData = async (url, setData, setError) => {
  try {
    // Use api.get, which includes the base URL and interceptors
    const response = await api.get(url);
    setData(response.data); // Set the fetched data from response
  } catch (error) {
    setError(error); // Handle error
  }
};

const Home = () => {
  const { dialBoxopen, isNavBarOpen } = useContext(ProjectContext);
  const { projectId } = useParams();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userDetails } = useAuth();
  const userID = userDetails.id;
  const username = userDetails.name;
  console.log("project data", projects);
  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return "Good morning,";
    } else if (currentHour < 16) {
      return "Good afternoon";
    } else {
      return "Good evening";
    }
  };
  const greeting = getGreeting();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const endpoint =
          userDetails.role_name === "Administrator" ||
          userDetails.role_name === "Project_Manager"
            ? `/api/projects1`
            : `/api/aprojects/${userID}`;

        const res = await api.get(endpoint);
        setProjects(res.data);
      } catch (error) {
        setError(error);
        console.error("Error fetching project data:", error.message || error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userDetails.role, userID]);

  return (
    <>
      <Helmet>
        <title>Msuite - Projects</title>
      </Helmet>
      {!dialBoxopen && (
        <div
          className={
            isNavBarOpen
              ? "home-dashboard-main-container"
              : "home-dashboard-main-container-close"
          }
        >
          <h1
            className={
              isNavBarOpen ? "greeting-content" : "greeting-content-close"
            }
          >
            Welcome {username},<br />{" "}
            <span className="greeting">{greeting}</span>
          </h1>
          <div
            className={
              isNavBarOpen ? "dashboard-container" : "dashboard-container-close"
            }
          >
            <div className="container-one">
              <StatusPieChart projects={projects} />
            </div>
            <div className="container-two">
              <GroupPieChart projects={projects} />
            </div>
          </div>
          <div
            className={
              isNavBarOpen ? "dashboard-container" : "dashboard-container-close"
            }
          >
            <div className="container-one">
              {" "}
              <ProjectManagerBarChart projects={projects} />
            </div>
            <div className="container-two">
              <ProjectTimelineSummary projects={projects} />
            </div>
          </div>
          <div
            className={
              isNavBarOpen
                ? "dashboard-container last-container"
                : "dashboard-container-close last-container-close"
            }
          >
            <div className="container-one">
              <ProjectCustomerBarChart projects={projects} />
            </div>
            <div className="container-two">
              <ul className="ul-dashboard"></ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
