import React, { useState, useEffect, useCallback } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { IoMdClose } from "react-icons/io";
import { SiConvertio } from "react-icons/si";
import "../../styles/projects/Estimation.css";
import { useAuth } from "../Authentication/AuthContext";
import api from "../../api/axiosConfig";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdKeyboardArrowRight,
} from "react-icons/md";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

export const Estimation = () => {
  // const [selectedStatus, setSelectedStatus] = useState("");
  const { userDetails } = useAuth();
  const [estimatedById, setEstimatedById] = useState("");
  const [estimatestasktemplateId, setEstimatestasktemplateId] = useState("");
  const [taskTemplates, setTaskTemplates] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [tasks, setTasks] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [taskErrors, setTaskErrors] = useState([]);
  const [estimates, setEstimates] = useState([]); // State to hold fetched estimates
  const [phaseTaskTemplates, setPhaseTaskTemplates] = useState([]);
  // const [selectedTaskTemplate, setSelectedTaskTemplate] = useState('');
  const [selectedPhaseTaskTemplate, setSelectedPhaseTaskTemplate] =
    useState("");
  const [selectedPhaseTaskTemplateName, setSelectedPhaseTaskTemplateName] =
    useState("");
  const [estimateTasks, setEstimateTasks] = useState([]);
  const [selectedEstimateTasks, setSelectedEstimateTasks] = useState([]);
  const [taskTemplateTasks, setTaskTemplateTasks] = useState([]);
  const [selectedEstimateId, setSelectedEstimateId] = useState("");
  const [estimateDetails, setEstimateDetails] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage, setProjectsPerPage] = useState(13);
  // const [pagination, setPagination] = useState({
  //   currentPage: 1,
  //   totalPages: 1,
  // });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEstDialogOpen, setIsEstDialogOpen] = useState(false);

  // Form States
  const [estimationno, setEstimationno] = useState("");
  const [projectname, setProjectname] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const updateProjectsPerPage = () => {
    if (window.innerWidth < 1919) {
      setProjectsPerPage(10); // Small screens (e.g., mobile)
    } else if (window.innerWidth >= 1920) {
      setProjectsPerPage(15); // Medium screens (e.g., tablet)
    } else {
      setProjectsPerPage(10); // Large screens (e.g., desktop)
    }
  };
  useEffect(() => {
    // Set initial projects per page
    updateProjectsPerPage();

    // Update projects per page when the window is resized
    window.addEventListener("resize", updateProjectsPerPage);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", updateProjectsPerPage);
  }, []);
  // Fetch customers, task templates, and status values on load
  useEffect(() => {
    if (userDetails && userDetails.id) {
      setEstimatedById(userDetails.id);
    }
    const fetchInitialData = async () => {
      try {
        const [customerRes, taskTemplateRes, statuRes, estimatesRes] =
          await Promise.all([
            api.get("/api/customers"),
            api.get("/api/estimatestasktemplates"),
            api.get("/api/get-status-values"),
            api.get("/api/estimates"),
          ]);
        console.log("inside try");
        console.log("estimates response", estimatesRes.data);
        setCustomers(customerRes.data);
        setTaskTemplates(taskTemplateRes.data);
        setStatus(statuRes.data);
        // setEstimates(estimatesRes.data);
        setEstimates(estimatesRes.data.estimates);
      } catch (error) {
        // Enhanced error handling
        if (error.response) {
          // Server responded with an error
          console.error(
            "Server Error:",
            error.response.data.error || error.response.statusText
          );
          setErrorMessage(
            `Server Error: ${
              error.response.data.error || error.response.statusText
            }`
          );
        } else if (error.request) {
          // No response from the server
          console.error("No response received:", error.request);
          setErrorMessage("No response received from the server.");
        } else {
          // Error setting up the request
          console.error("Request Error:", error.message);
          setErrorMessage(`Request Error: ${error.message}`);
        }
      }
    };

    fetchInitialData();
  }, [userDetails]);

  // Fetch contacts based on selected customer
  const handleCustomerChange = useCallback(async (e) => {
    const selectedCustomerId = e.target.value;
    setSelectedCustomer(selectedCustomerId);
    setSelectedContact(""); // Reset contact selection

    try {
      const { data } = await api.get(
        `/api/customers/${selectedCustomerId}/contacts`
      );
      setContacts(data);
    } catch (error) {
      console.error("Error fetching contacts:", error);
      setContacts([]);
    }
  }, []);

  const handleTaskTimeChange = (index, value) => {
    const updatedTasks = [...tasks];

    if (value === "") {
      updatedTasks[index].hours = ""; // Set empty value
    } else {
      updatedTasks[index].hours = value;
    }
    setTasks(updatedTasks);
  };
  const validateTaskTime = (index, value) => {
    // Ensure the value is in HH:mm format
    const timeFormat = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/; // Matches HH:mm format

    const updatedErrors = [...taskErrors]; // Copy the current error state

    if (value === "" || timeFormat.test(value)) {
      updatedErrors[index] = ""; // Clear error message if time is valid
    } else {
      updatedErrors[index] =
        "Invalid time format. Please enter time in HH:mm format"; // Set error message for this task
    }

    // Update the error state
    setTaskErrors(updatedErrors);
  };

  // Fetch tasks based on selected task template
  useEffect(() => {
    if (estimatestasktemplateId) {
      api
        .get(`/api/estimatestasks/${estimatestasktemplateId}`)
        .then((response) => {
          setTasks(
            response.data.map((task) => ({
              ...task,
              hours: "", // Add an empty field for hours
            }))
          );
        })
        .catch((error) => {
          console.error("Error fetching tasks:", error);
          setErrorMessage("Failed to fetch tasks");
        });
    }
  }, [estimatestasktemplateId]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Submitted"); // Debugging log

    if (!estimationno.trim()) {
      setErrorMessage("Estimation number is required");
      return;
    }

    const userDefinedTasks = tasks.map((task) => {
      const [hour, minute] = task.hours.split(":");
      const formattedTime = task.hours ? `${hour}:${minute}:00` : ""; // Format the time to HH:mm:ss

      return {
        name: task.name,
        hours: formattedTime, // Use formatted time
      };
    });

    const requestData = {
      estimationno,
      projectname,
      customer_id: selectedCustomer,
      contact_id: selectedContact,
      description,
      status,
      estimatedby_id: estimatedById,
      estimatestasktemplate_id: estimatestasktemplateId,
      userDefinedTasks,
    };

    try {
      const response = await api.post("/api/estimates", requestData);
      alert(response.data.message);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error || "Failed to create estimation"
      );
    }
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    resetForm();
  };
  const handleEstOpenDialog = () => {
    setIsEstDialogOpen(true);
  };

  const handleEstCloseDialog = () => {
    setIsEstDialogOpen(false);
    resetForm();
  };
  const resetForm = () => {
    setEstimationno("");
    setProjectname("");
    setDescription("");
    setStatus("");
    setTasks([]);
    setErrorMessage("");
  };
  const handleConvert = async (estimateId) => {
    setIsDialogOpen(true);
    setSelectedEstimateId(estimateId);
    console.log("estimateid", selectedEstimateId, estimateId);
    // Fetch the specific estimate tasks for this estimateId
    try {
      const response = await api.get(`/api/estimatetasks/${estimateId}`);
      const tasks = response.data.tasks;
      console.log("estimatetasks1:", tasks);
      setEstimateTasks(tasks); // Set the estimate tasks to the state
    } catch (error) {
      console.error("Error fetching estimate tasks:", error);
    }
  };
  // Fetch task templates when component mounts
  useEffect(() => {
    const fetchTaskTemplates = async () => {
      try {
        const response = await api.get("/api/fetchtasktemplates");
        setPhaseTaskTemplates(response.data); // Assuming response contains task templates
      } catch (error) {
        console.error("Error fetching task templates:", error);
      }
    };

    fetchTaskTemplates();
  }, []);

  // Fetch tasks based on selected task template
  useEffect(() => {
    if (selectedPhaseTaskTemplate) {
      const fetchTemplateTasks = async () => {
        try {
          const response = await api.get(
            `/api/ptasks/${selectedPhaseTaskTemplate}`
          );
          setTaskTemplateTasks(response.data); // Assuming response contains tasks for the selected template
        } catch (error) {
          console.error("Error fetching template tasks:", error);
        }
      };

      fetchTemplateTasks();
    }
  }, [selectedPhaseTaskTemplate]);
  const handleTaskSelectionChange = (index, selectedTaskId) => {
    const updatedTasks = [...estimateTasks];
    updatedTasks[index].selectedTask = selectedTaskId; // Set the selected task ID for this task
    setEstimateTasks(updatedTasks);
  };

  const addDaysAndFormatDate = (daysToAdd) => {
    // Get today's date
    const validDate = new Date();

    // Add the specified number of days
    validDate.setDate(validDate.getDate() + daysToAdd);

    // Adjust for timezone and return as 'YYYY-MM-DD'
    const localDate = new Date(
      validDate.getTime() - validDate.getTimezoneOffset() * 60000
    );

    // Convert to 'YYYY-MM-DD' format
    return localDate.toISOString().split("T")[0];
  };

  const handleSubmitConvert = async (e) => {
    e.preventDefault();

    const requestData = {
      estimateId: selectedEstimateId, // Track the selected estimate ID
      tasks: estimateTasks.map((task) => ({
        taskId: task.taskId,
        hours: task.plannedHours,
      })),
    };

    try {
      // Step 1: Fetch Estimate Details
      const response = await api.get(`/api/estimate/${requestData.estimateId}`);
      const estimateDetailsArray = response.data;
      // const estimateDetails = response.data;

      if (!estimateDetailsArray || estimateDetailsArray.length === 0) {
        alert("Failed to fetch estimate details.");
        return;
      }
      const estimateDetails = estimateDetailsArray[0];
      console.log("Estimate Details:", estimateDetails);

      // Check if all required fields are present
      const requiredFields = [
        "customer_id",
        "contact_id",
        "description",
        "manager_id",
        "status",
        "projectName",
      ];

      for (const field of requiredFields) {
        if (estimateDetails[field] === undefined) {
          console.error(`Missing required field: ${field}`);
          alert(`Estimate details are incomplete: missing ${field}.`);
          return;
        }
      }

      // Step 2: Insert a New Project
      const projectData = {
        name: estimateDetails.projectName,
        projectno: estimateDetails.projectName,
        customer_id: estimateDetails.customer_id,
        contact_id: estimateDetails.contact_id,
        description: estimateDetails.description,
        status_id: estimateDetails.status,
        manager_id: estimateDetails.manager_id,
        start_date: addDaysAndFormatDate(0),
        end_date: addDaysAndFormatDate(30),
      };

      console.log("Project Data:", projectData);
      const projectRes = await api.post("/api/estimate_projects", projectData);
      const newProjectId = projectRes.data.id;

      // Step 3: Create Phase 1 for the New Project
      const phaseData = {
        project_id: newProjectId,
        title: "NewProject-Phase 1",
        phaseno: "NewProject-Phase 1",
        plannedhours: 0,
      };

      const phaseRes = await api.post(
        `/api/estimate_project/${newProjectId}/phases`,
        phaseData
      );
      const newPhaseId = phaseRes.data.id;

      console.log("Phase Created:", newPhaseId);

      // Step 4: Add Tasks to Phase
      try {
        const taskData = {
          estimateId: selectedEstimateId,
          templateName: selectedPhaseTaskTemplateName,
          tasks: estimateTasks.map((task) => ({
            taskId: task.taskId,
            taskName: task.taskName,
            plannedHours: task.plannedHours,
            selectedTask: task.selectedTask, // Newly mapped task
          })),
          phaseId: newPhaseId, // Pass the newly created phase ID
        };
        console.log("TasksData:", taskData);
        const response = await api.post(
          "/api/convert-estimate-to-phase",
          taskData
        );
        alert(response.data.message);
        console.log("selectedPhaseTaskTemplate", selectedPhaseTaskTemplate);
      } catch (error) {
        // Optionally refresh the page or close the dialog

        console.error("Error mapping phases:", error);
        alert("Failed to map tasks in phases.");
      }
    } catch (error) {
      console.error("Error converting estimate to project:", error);
      alert("Failed to convert estimation to project.");
    }
  };
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };
  const sortedEstimates = [...estimates].sort((a, b) => {
    if (!sortConfig?.key) return 0; // No sorting if no key is specified

    const { key, direction } = sortConfig;
    const sortDirection = direction === "asc" ? 1 : -1;

    let aValue = a[key];
    let bValue = b[key];

    // Handle specific sorting cases based on table content
    switch (key) {
      case "estimationno": // Sort as string or number if estimation numbers are mixed
        aValue = String(aValue || "").toLowerCase();
        bValue = String(bValue || "").toLowerCase();
        break;
      case "projectname": // Sort by project name (string)
      case "status": // Sort by status (string)
        aValue = aValue?.toLowerCase() || "";
        bValue = bValue?.toLowerCase() || "";
        break;
      case "user_name": // Sort by status (string)
        aValue = aValue?.toLowerCase() || "";
        bValue = bValue?.toLowerCase() || "";
        break;
      case "customer_name": // Sort by status (string)
        aValue = aValue?.toLowerCase() || "";
        bValue = bValue?.toLowerCase() || "";
        break;
      case "planned_hours": // Sort by total planned hours (HH:MM:SS format)
        const timeToSeconds = (time) => {
          if (!time) return 0;
          const [hours, minutes, seconds] = time.split(":").map(Number);
          return hours * 3600 + minutes * 60 + seconds;
        };
        aValue = timeToSeconds(aValue);
        bValue = timeToSeconds(bValue);
        break;
      case "description": // Sort by description (string)
        aValue = aValue?.toLowerCase() || "";
        bValue = bValue?.toLowerCase() || "";
        break;
      default:
        // Fallback: If the field is unexpected, return no sorting
        return 0;
    }

    // Perform sorting
    if (aValue < bValue) return -1 * sortDirection;
    if (aValue > bValue) return 1 * sortDirection;
    return 0;
  });

  // Handle pagination with fetched estimates
  const totalPages = Math.ceil(sortedEstimates.length / projectsPerPage);
  console.log("estimates", estimates);
  console.log("sorted estimates", sortedEstimates);
  // Get current projects to display
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = sortedEstimates.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  console.log("current project", currentProjects);

  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleFirstPage = () => {
    if (currentPage > 1) setCurrentPage(1);
  };

  const handleLastPage = () => {
    if (currentPage < totalPages) setCurrentPage(totalPages);
  };

  return (
    <div className="estimation-container">
      {/* <h3>Existing Estimates</h3> */}
      <button onClick={handleEstOpenDialog} className="create-estimate-btn">
        Create Estimation
      </button>
      <table border="1" className="estimation-content-table">
        <thead>
          <tr>
            <th onClick={() => handleSort("estimationno")}>
              Estimation No{" "}
              {sortConfig?.key === "estimationno"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th onClick={() => handleSort("projectname")}>
              Project Name{" "}
              {sortConfig?.key === "projectname"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th onClick={() => handleSort("status")}>
              Status{" "}
              {sortConfig?.key === "status"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th onClick={() => handleSort("user_name")}>
              Estimated by
              {sortConfig?.key === "user_name"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th onClick={() => handleSort("customer_name")}>
              Customer Name
              {sortConfig?.key === "customer_name"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th onClick={() => handleSort("description")}>
              Description{" "}
              {sortConfig?.key === "description"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th onClick={() => handleSort("planned_hours")}>
              Total Hours{" "}
              {sortConfig?.key === "planned_hours"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th className="convert-column">
              <SiConvertio />
            </th>
          </tr>
        </thead>
        <tbody>
          {estimates.length > 0 ? (
            currentProjects.map((estimate) => (
              <tr key={estimate.id}>
                <td>{estimate.estimationno}</td>
                <td>{estimate.projectname}</td>
                <td>{estimate.status}</td>
                <td>{estimate.user_name}</td>
                <td>{estimate.customer_name}</td>
                <td>{estimate.description}</td>
                <td>{estimate.planned_hours}</td>
                <td className="convert-column">
                  <button
                    onClick={() => handleConvert(estimate.id)}
                    className="convert-btn"
                  >
                    Convert
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No estimates found</td>
            </tr>
          )}
        </tbody>
      </table>
      {/* Pagination Controls */}
      {/* Pagination Controls */}
      <div className="estimation-pagination">
        <button
          className="arrow-project-btn"
          onClick={handleFirstPage}
          disabled={currentPage === 1}
        >
          <MdKeyboardDoubleArrowLeft title="First Page" />
        </button>
        <button
          className="arrow-project-btn"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          <MdKeyboardArrowLeft title="Previous Page" />
        </button>
        <span
          style={{
            marginTop: "16px",
            textWrap: "nowrap",
          }}
        >
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="arrow-project-btn"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <MdKeyboardArrowRight title="Next Page" />
        </button>
        <button
          className="arrow-project-btn"
          onClick={handleLastPage}
          disabled={currentPage === totalPages}
        >
          <MdKeyboardDoubleArrowRight title="Last Page" />
        </button>
      </div>
      {/* Button to Open Create Estimation Dialog */}
      {/* Create Estimation Dialog */}
      {isEstDialogOpen && (
        <div className="timesheet-overlay-add-time-log">
          <div className="new-time-log">
            <h3 className="add-estimation-heading">Create Estimation</h3>
          </div>
          <div className="scrollable-e-content">
            {errorMessage && <div className="error">{errorMessage}</div>}
            <form id="estimation-form" onSubmit={handleSubmit}>
              <div>
                <h3 className="user-name">Estimation Number:</h3>
                <input
                  className="timesheet-d-input"
                  placeholder="Enter Estimation Number"
                  type="text"
                  value={estimationno}
                  onChange={(e) => setEstimationno(e.target.value)}
                  required
                />
              </div>
              <div>
                <h3 className="user-name">Project Name:</h3>
                <input
                  className="timesheet-d-input"
                  placeholder="Enter Project Name"
                  type="text"
                  value={projectname}
                  onChange={(e) => setProjectname(e.target.value)}
                  required
                />
              </div>
              <div>
                <h3 className="user-name">Description:</h3>
                <input
                  type="text"
                  className="timesheet-d-input"
                  placeholder="Enter Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              {/* Customer Selection with Name */}
              <div>
                <h3 className="user-name">Customer:</h3>
                <select
                  className="timesheet-input"
                  value={selectedCustomer}
                  onChange={handleCustomerChange}
                  required
                >
                  <option value="" disabled>
                    Select Customer
                  </option>
                  {customers.map((customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Contact Selection based on Customer */}
              <div>
                <h3 className="user-name">Contact:</h3>
                <select
                  className="timesheet-input"
                  value={selectedContact}
                  onChange={(e) => setSelectedContact(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select Contact
                  </option>
                  {contacts.map((contact) => (
                    <option key={contact.contact_id} value={contact.contact_id}>
                      {contact.contact_name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <h3 className="user-name">Status:</h3>
                <select
                  className="timesheet-input"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="" disabled>
                    Select Status
                  </option>

                  <option value="Yet_to_quote">Yet to Quote</option>
                  <option value="Waiting">Waiting</option>
                  <option value="Awarded">Awarded</option>
                  <option value="Lost">Lost</option>
                </select>
              </div>
              <div className="user-name">
                <h3>Task Template:</h3>
                <select
                  className="timesheet-input"
                  value={estimatestasktemplateId}
                  onChange={(e) => setEstimatestasktemplateId(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select Template
                  </option>
                  {taskTemplates.map((template) => (
                    <option key={template.id} value={template.id}>
                      {template.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Render tasks with input fields for hours */}
              <div>
                <h3 className="user-name">Tasks from Selected Template</h3>
                {tasks.length > 0 ? (
                  tasks.map((task, index) => (
                    <div key={index} className="task-hours-container">
                      <label className="user-name">{task.name}:</label>
                      <input
                        className="hours-input"
                        type="text"
                        value={task.hours}
                        onChange={(e) =>
                          handleTaskTimeChange(index, e.target.value)
                        } // Call handleTaskTimeChange
                        onBlur={(e) => validateTaskTime(index, e.target.value)}
                        placeholder="Enter hours (HH:mm)"
                      />
                      {/* Show error message for invalid time format */}
                      {taskErrors[index] && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {taskErrors[index]}
                        </span>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No tasks available for the selected template.</p>
                )}
              </div>
              {/* <button onclick={handleSubmit}>Submit</button> */}
            </form>
          </div>

          <div className="btn-container-add-timesheet">
            {/* <button form="estimation-form" type="submit" color="primary">
              Submit
            </button> */}
            <button onclick={handleSubmit} className="add-btn">
              Submit
            </button>
            <button onClick={handleEstCloseDialog} className="cancel-btn">
              Cancel
            </button>
          </div>
        </div>
      )}
      {isDialogOpen && (
        <div className="convert-main-dial">
          <div
            // open={isDialogOpen}
            // onClose={handleCloseDialog}
            // maxWidth="md"
            // fullWidth
            className="convert-dial"
          >
            <div className="convert-dial-header">
              <h3 className="convert-heading">Convert Estimate</h3>
            </div>
            <div className="estimate-map-content">
              <form id="convert-form" onSubmit={handleSubmitConvert}>
                <div>
                  <label className="user-name">Task Template:</label>
                  <select
                    className="tt-input"
                    value={selectedPhaseTaskTemplate}
                    onChange={(e) => {
                      const selectedOption = phaseTaskTemplates.find(
                        (template) =>
                          template.templateId === parseInt(e.target.value, 10)
                      );
                      setSelectedPhaseTaskTemplate(e.target.value);
                      setSelectedPhaseTaskTemplateName(
                        selectedOption.templateName
                      ); // Update templateName state
                    }}
                    required
                  >
                    <option value="">Select Task Template</option>
                    {phaseTaskTemplates.map((template) => (
                      <option
                        key={template.templateId}
                        value={template.templateId}
                      >
                        {template.templateName}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Estimate Tasks */}
                <div className="mapping-main-container">
                  {estimateTasks && estimateTasks.length > 0 ? (
                    estimateTasks.map((task, index) => (
                      <div key={task.taskId} className="task-mapping">
                        {/* Display Task Name and Planned Hours */}
                        <label className="user-name">
                          {task.taskName} Planned Hours : {task.plannedHours}
                        </label>

                        {/* Dropdown to map tasks */}
                        <select
                          className="task-dd"
                          value={task.selectedTask || ""}
                          onChange={(e) =>
                            handleTaskSelectionChange(index, e.target.value)
                          }
                          style={{ marginLeft: "1rem" }}
                        >
                          <option value="">Select Task</option>
                          {taskTemplateTasks.map((templateTask) => (
                            <option
                              key={templateTask.id}
                              value={templateTask.name}
                            >
                              {templateTask.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    ))
                  ) : (
                    <p>No tasks available for this estimate.</p>
                  )}
                </div>
              </form>
            </div>
            <div className="convert-btn-container">
              <button
                form="convert-form"
                type="submit"
                color="primary"
                className="add-btn"
              >
                Convert
              </button>
              <button onClick={handleCloseDialog} className="cancel-btn">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
