import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const ProjectTimelineChart = ({ projects }) => {
  // Helper function to get the months between start and end dates
  const getMonthsInRange = (startDate, endDate) => {
    const months = [];
    let currentMonth = new Date(startDate);
    while (currentMonth <= new Date(endDate)) {
      months.push(
        currentMonth.toLocaleString("default", {
          month: "long",
          year: "numeric",
        })
      );
      currentMonth.setMonth(currentMonth.getMonth() + 1);
    }
    return months;
  };

  // Step 1: Prepare the data
  const projectTimeline = [];
  projects.forEach((project) => {
    const monthsInRange = getMonthsInRange(
      project.start_date,
      project.end_date
    );
    monthsInRange.forEach((month) => {
      if (!projectTimeline[month]) {
        projectTimeline[month] = 0;
      }
      projectTimeline[month] += 1; // Count the projects for each month
    });
  });

  // Step 2: Prepare chart data
  const labels = Object.keys(projectTimeline);
  const data = Object.values(projectTimeline);

  const chartData = {
    labels: labels, // Months as labels
    datasets: [
      {
        label: "Number of Projects",
        data: data,
        fill: true, // Fill the area below the line
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Project Timeline Summary",
        Tooltip: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Month",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Projects",
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="project-bar-status">
      <h2 className="pie-header">Project Timeline Summary</h2>
      <Line
        data={chartData}
        className="timeline-summary"
        style={{ width: "100%" }}
      />
      {/* // options={options}
      // style={{ height: "393px", width: "800px", position: "relative" }} */}
    </div>
  );
};

export default ProjectTimelineChart;
