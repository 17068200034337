import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgress } from "@mui/material";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { PiColumnsPlusRight } from "react-icons/pi";
import { GrHide } from "react-icons/gr";
import { IoMdClose } from "react-icons/io";
import DatePicker from "react-datepicker";
import "../../styles/projects/Projects.css";
import { MultiSelect } from "react-multi-select-component";
import { useAuth } from "../Authentication/AuthContext";
import { useRolePermissions } from "../Settings/RoleContext";
import { FaChevronDown } from "react-icons/fa";
import api from "../../api/axiosConfig";
import { ProjectContext } from "../../App";
import { Helmet } from "react-helmet";
import format from "date-fns/format";
import Papa from "papaparse";
import axios from "axios";

const fetchData = async (url, setData, setError) => {
  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error("Network response was not ok");
    const data = await response.json();
    setData(data);
  } catch (error) {
    setError(error);
  }
};

const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  const projectRef = useRef(null);
  const { isNavBarOpen } = useContext(ProjectContext);
  const { userDetails } = useAuth();
  const { hasPermission } = useRolePermissions();
  const userID = userDetails.id;
  const [loading, setLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [newProject, setNewProject] = useState({
    name: "",
    projectno: "",
    start_date: "",
    end_date: "",
    actual_hours: "",
    budget_hours: "",
    manager_id: "",
    description: "",
    customer_id: "",
    team_id: "",
    tags: "",
  });
  const [customers, setCustomers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [users, setUsers] = useState([]);
  const [associatedUsers, setAssociatedUsers] = useState([]);
  const [nonAssociatedUsers, setNonAssociatedUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [customersDropdowm, setCustomersDropdown] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [updatedProject, setUpdatedProject] = useState(null);
  const [projectGroup, setProjectGroup] = useState([]);
  const [projectToDelete, setProjecttoDelete] = useState([]);
  const [filter, setFilter] = useState("");
  const tableHeading = [
    "Project No",
    "Project Name",
    "Customer Name",
    "Budget Hours",
    "Actual Hours",
    "Tags",
    "Project Manager",
    "Start Date",
    "Due Date",
    "Invoice Status",
    "Project Status",
    "Description",
  ];
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [activeProjLink, setProjActiveLink] = useState(() => {
    return localStorage.getItem("activeproject") || "activeprojects";
  });
  const [csvFile, setCsvFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const handleProjLinkClick = (link) => {
    setProjActiveLink(link); // Update the active link
    localStorage.setItem("activeproject", link);
  };
  const sampleCSV = "/SampleProjectFile/projects.csv";
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage, setProjectsPerPage] = useState(13);

  const [visibleColumns, setVisibleColumns] = useState({
    //state for column visibility
    // delete: true,
    "Project No": true,
    "Project Name": true,
    "Customer Name": true,
    "Budget Hours": true,
    "Actual Hours": true,
    Tags: true,
    "Project Manager": true,
    "Start Date": true,
    "Due Date": true,
    "Invoice Status": true,
    "Project Status": true,
    Description: true,
  });
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    column: null,
  });
  const [columns, setColumns] = useState(tableHeading);
  const [draggedColumnIndex, setDraggedColumnIndex] = useState(null);
  // Function to save user preferences to the backend
  const saveUserPreferences = async (preferences) => {
    const userId = userID; // Replace with the actual logged-in user's ID

    try {
      const response = await api.post("/api/save-column-preferences", {
        userId,
        preferences,
      });
    } catch (error) {
      console.error("Error saving preferences:", error);
    }
  };

  const fetchUserPreferences = async (userID) => {
    try {
      const response = await api.get(`/api/get-column-preferences/${userID}`);
      return response.data; // Axios automatically parses JSON responses
    } catch (error) {
      console.error("Error fetching preferences:", error);
      return null;
    }
  };

  const [isFormVisible, setIsFormVisible] = useState(false);
  const toggleFormVisibility = () => {
    setIsFormVisible((prevState) => !prevState);
  };
  //function for column visibility
  const toggleColumnVisibility = (columnKey) => {
    const updatedVisibleColumns = {
      ...visibleColumns,
      [columnKey]: !visibleColumns[columnKey],
    };

    // Update the state with the new visibility
    setVisibleColumns(updatedVisibleColumns);

    // Call the API to save the updated preferences
    saveUserPreferences(updatedVisibleColumns);
  };
  const handleRightClick = (event, column) => {
    event.preventDefault();
    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      column: column,
    });
  };

  const hideColumn = async () => {
    if (contextMenu.column) {
      const updatedColumns = {
        ...visibleColumns,
        [contextMenu.column]: false,
      };
      setVisibleColumns(updatedColumns);
      setContextMenu({ ...contextMenu, visible: false });
      saveUserPreferences(updatedColumns);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the context menu
      if (projectRef.current && !projectRef.current.contains(event.target)) {
        setContextMenu({ ...contextMenu, visible: false });
      }
    };

    // Attach event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [contextMenu]);

  const handleDragStart = (index) => {
    setDraggedColumnIndex(index);
  };

  const handleDrop = (dropIndex) => {
    if (draggedColumnIndex !== null) {
      // Clone columns array and reorder it
      const updatedColumns = [...columns];
      const [draggedColumn] = updatedColumns.splice(draggedColumnIndex, 1);
      updatedColumns.splice(dropIndex, 0, draggedColumn);
      console.log("Updated columns:", updatedColumns);

      // Update the columns in state
      setColumns(updatedColumns);

      // Update visibility order to match the new column order
      const newVisibleColumns = {};
      updatedColumns.forEach((col) => {
        newVisibleColumns[col] = visibleColumns[col];
      });
      setVisibleColumns(newVisibleColumns);
      console.log("New visibility order:", newVisibleColumns);

      // Save preferences to persist updated order and visibility
      saveUserPreferences(newVisibleColumns);

      // Reset draggedColumnIndex
      setDraggedColumnIndex(null);
    }
  };

  const updateProjectsPerPage = () => {
    if (window.innerWidth < 1919) {
      setProjectsPerPage(9); // Small screens (e.g., mobile)
    } else if (window.innerWidth >= 1920) {
      setProjectsPerPage(13); // Medium screens (e.g., tablet)
    } else {
      setProjectsPerPage(10); // Large screens (e.g., desktop)
    }
  };
  //saved preferences
  useEffect(() => {
    // Fetch the user preferences when the component mounts
    const loadPreferences = async () => {
      const preferences = await fetchUserPreferences(userID);
      if (preferences) {
        setVisibleColumns(preferences);
        setColumns(Object.keys(preferences));
      } else {
        // Set default preferences if no saved preferences found
        const defaultColumns = {
          // delete: true,
          "Project No": true,
          "Project Name": true,
          "Customer Name": true,
          "Budget Hours": true,
          "Actual Hours": true,
          Tags: true,
          "Project Manager": true,
          "Start Date": true,
          "Due Date": true,
          "Invoice Status": true,
          "Project Status": true,
          Description: true,
        };
        setVisibleColumns(defaultColumns);
        setColumns(Object.keys(defaultColumns));
      }
    };

    loadPreferences();
  }, [userID]);
  //saved preferences

  useEffect(() => {
    // Set initial projects per page
    updateProjectsPerPage();

    // Update projects per page when the window is resized
    window.addEventListener("resize", updateProjectsPerPage);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", updateProjectsPerPage);
  }, []);
  const [columnWidths, setColumnWidths] = useState(
    Array(7).fill(50) // assuming 9 columns in your table
  );
  const startX = useRef(null);
  const startWidth = useRef(null);
  const activeColumnIndex = useRef(null);

  const handleMouseDown = (index, e) => {
    startX.current = e.pageX;
    startWidth.current = columnWidths[index];
    activeColumnIndex.current = index;

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (activeColumnIndex.current !== null) {
      let newWidth = startWidth.current + (e.pageX - startX.current);
      newWidth = Math.max(50, Math.min(newWidth, 500)); // Use reasonable limits
      setColumnWidths((prevWidths) => {
        const updatedWidths = [...prevWidths];
        updatedWidths[activeColumnIndex.current] = newWidth;
        return updatedWidths;
      });
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    activeColumnIndex.current = null;
  };

  const handleOwnerChange = (event) => {
    setSelectedOwner(event.target.value);
  };
  //update project
  const handleEditClick = (project) => {
    setSelectedProject(project);
    setUpdatedProject({ ...project });
    setOpenEditDialog(true);
  };

  //close delete dial box
  const handleCloseDial = () => {
    setIsCheckboxChecked(false);
    setDeleteDialogOpen(false);
    document.body.classList.remove("no-scroll");
  };
  //add project event handling
  const onHandleAddProject = () => {
    setOpenDialog(true);
    document.body.classList.add("no-scroll");
  };
  //new project dial
  const closeDialog = () => {
    setOpenDialog(false);
    document.body.classList.remove("no-scroll");
  };

  //add new project
  const handleAddNewProject = async () => {
    setLoading(true);
    const formatDateForMySQL = (date, daysToAdd = 0) => {
      if (!date) return null; // If no date is provided, return null

      // Convert to Date object if it's a valid date string
      let validDate = new Date(date);
      if (isNaN(validDate)) {
        return null; // Return null if invalid date
      }

      // Add the specified number of days (30 in this case)
      validDate.setDate(validDate.getDate() + daysToAdd);

      // Adjust for timezone and return as 'YYYY-MM-DD'
      const localDate = new Date(
        validDate.getTime() - validDate.getTimezoneOffset() * 60000
      );
      return localDate.toISOString().split("T")[0]; // Convert to 'YYYY-MM-DD'
    };

    let formattedProject;
    if (newProject.start_date && newProject.end_date) {
      formattedProject = {
        ...newProject,
        start_date: formatDateForMySQL(newProject.start_date),
        end_date: formatDateForMySQL(newProject.end_date),
      };
    } else {
      formattedProject = {
        ...newProject,
        start_date: formatDateForMySQL(newProject.start_date),
        end_date: formatDateForMySQL(newProject.start_date, 30), // Add 30 days to the start_date
      };
    }
    console.log("formatted project", formattedProject);
    if (
      !formattedProject.projectno ||
      !formattedProject.name ||
      !formattedProject.start_date ||
      !formattedProject.customer_id ||
      !formattedProject.manager_id ||
      !formattedProject.tags
    ) {
      alert("Check the input fields");
    } else {
      try {
        const response = await api.post(`/api/projects`, formattedProject);
        if (response.status === 201) {
          const newProject = response.data; // Newly created project
          const currentProjectId = newProject.id; // Get project ID

          setProjects([...projects, newProject]);
          alert("Project added successfully!");
          window.location.reload();

          // After project is added, associate selected members with the new project
          if (selectedMembers.length > 0) {
            const userIds = selectedMembers.map((member) => member.value); // Extract 'value' instead of 'id'
            await handleAssociateUsersToProject(currentProjectId, userIds);
          }

          setNewProject({
            name: "",
            projectno: "",
            start_date: "",
            end_date: "",
            actual_hours: "",
            budget_hours: "",
            manager_id: "",
            description: "",
            customer_id: "",
            team_id: "",
            tags: "",
          });
          closeDialog();
        }
      } catch (error) {
        console.error("Error adding project:", error);
        alert("Failed to add project");
      } finally {
        setLoading(false);
      }
    }
  };

  //add users to an project
  const handleAssociateUsersToProject = async (currentProjectId, userIds) => {
    setLoading(true);
    if (userIds.length === 0 || userIds.includes(undefined)) {
      console.error("Error: Invalid user IDs", userIds);
      alert("Error: Invalid user selection");
      return;
    }
    try {
      const response = await api.post(
        `/api/projects/${currentProjectId}/users`,
        {
          userIds: userIds,
        }
      );
      const data = response.data;
      alert("Users associated with the project successfully.");
      setSelectedMembers((prev) => "");
      // Update associated and non-associated users state
      setAssociatedUsers((prev) => [
        ...prev,
        ...users.filter((user) => userIds.includes(user.id)),
      ]);
      setNonAssociatedUsers((prev) =>
        prev.filter((user) => !userIds.includes(user.id))
      );
    } catch (error) {
      console.error(
        "Error associating users with project:",
        error.response?.data || error.message
      );
      alert("Error associating users with project. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  //edit dial box
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target; // `name` will be "managerId", `value` will be the selected manager's id
    setUpdatedProject((prev) => ({
      ...prev,
      [name]: value, // This will dynamically update the corresponding field in the updatedProject state
    }));
  };

  const handleDateChange = (date, field) => {
    setUpdatedProject((prev) => ({
      ...prev,
      [field]: date.toISOString().split("T")[0],
    }));
  };

  const handleUpdateProject = async () => {
    setLoading(true);
    const { start_date, end_date, manager_id, ...updateData } = updatedProject;

    // Validate and format start and end dates
    const validStartDate =
      start_date && !isNaN(Date.parse(start_date))
        ? new Date(start_date).toISOString().split("T")[0]
        : selectedProject.start_date;

    const validEndDate =
      end_date && !isNaN(Date.parse(end_date))
        ? new Date(end_date).toISOString().split("T")[0]
        : selectedProject.end_date;

    // Add the validated/formatted dates to updateData
    updateData.start_date = validStartDate;
    updateData.end_date = validEndDate;

    // Include manager_id in updateData for the API call
    updateData.manager_id = manager_id;

    // Log statements for debugging

    try {
      const response = await api.put(
        `/api/projects/${selectedProject.id}`,
        updateData
      );

      if (response.status === 200) {
        // Find updated manager's name if the manager_id has changed
        let updatedManagerName = selectedProject.manager_name; // Default to current name
        if (manager_id !== selectedProject.manager_id) {
          const updatedManager = managers.find(
            (manager) => manager.id === manager_id
          );
          updatedManagerName = updatedManager
            ? updatedManager.name
            : updatedManagerName; // Set new manager name if exists
        }

        // Update the projects state with new values
        setProjects((prev) =>
          prev.map((proj) =>
            proj.id === selectedProject.id
              ? {
                ...proj, // Retain other existing fields
                ...updateData, // Spread updateData to retain updated fields
                project_manager_name: updatedManagerName, // Update manager name
              }
              : proj
          )
        );
        alert("Project updated successfully!");
        // window.location.reload();
        handleCloseEditDialog();
      } else {
        alert("Failed to update project");
      }
    } catch (error) {
      console.error("Error updating project:", error);
      alert("Failed to update project");
    } finally {
      setLoading(false);
    }
  };

  //fetching admin users
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/api/users/admin`);
        const activeUsers = res.data.filter(
          (member) => member.status !== "Inactive"
        );
        setUsers(activeUsers);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  //fetching all users and customers
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/api/users`);
        const cusres = await api.get(`/api/customers`);
        const groupres = await api.get(`/api/groups`);
        const formattedMembers = res.data
          // .filter((member) => member.status !== "Inactive")
          .map((member) => ({
            value: member.id,
            label: member.name,
          }))
          .sort((a, b) => {
            return a.label.localeCompare(b.label);
          });
        setMembers(formattedMembers);
        setCustomers(cusres.data);
        setProjectGroup(groupres.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false); // Always stop loading after attempting to fetch
      }
    };

    fetchData();
  }, []);

  // fetching project details
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const endpoint =
          userDetails.role_name === "Administrator" ||
            userDetails.role_name === "Project_Manager"
            ? `/api/projects1`
            : `/api/aprojects/${userID}`;

        const res = await api.get(endpoint);
        console.log("project data", res.data);
        const managerResult = await api.get("/api/users/admin");
        setManagers(managerResult.data);
        setProjects(res.data);
      } catch (error) {
        setError(error);
        console.error("Error fetching project data:", error.message || error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userDetails.role, userID]);

  useEffect(() => {
    setLoading(true);
    // fetchData("/api/users", setManagers, setError);
    fetchData("/api/customers", setCustomersDropdown, setError);
  }, []);

  //delete a project
  const handleDeleteConfirm = async () => {
    setLoading(true);
    const idsToDelete = projectToDelete.map((project) => project.id);

    setDeleteDialogOpen(false); // Close the delete confirmation dialog

    try {
      const response = await api.delete("/api/projects", {
        data: { ids: idsToDelete }, // Send the IDs in the request body
      });

      // Check for successful deletion
      if (response.status === 204) {
        const updatedProjects = projects.filter(
          (project) => !idsToDelete.includes(project.id)
        );
        setProjects(updatedProjects); // Update the projects state
        setLoading(false);
      } else {
        console.error("Failed to delete projects");
        alert("Failed to delete projects. Please try again.");
      }
    } catch (error) {
      console.error("There was an error deleting the projects:", error);
      alert("An error occurred while deleting projects. Please try again.");
    } finally {
      setProjecttoDelete([]); // Clear the projects to delete
      handleCloseDial(); // Close any dialog
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]); // Set the selected CSV file
  };

  const handleParse = () => {
    if (!csvFile) return alert("Please upload a CSV file");

    // Use PapaParse to parse the CSV file
    Papa.parse(csvFile, {
      complete: function (result) {
        setCsvData(result.data); // Parsed CSV data
      },
      header: true, // Treat the first row as headers
    });
  };

  const handleUpload = async () => {
    if (csvData.length === 0) return alert("No data to upload");

    try {
      // Send CSV data (array of objects) to the backend
      const response = await axios.post("/api/projects/bulk", csvData);
      if (response.status === 200) {
        alert("Projects uploaded successfully");
      }
    } catch (error) {
      console.error("Error uploading projects:", error);
      alert("Failed to upload projects");
    }
  };

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  }

  const mapKeyToProjectProperty = (heading) => {
    switch (
    heading.trim() // Use trim() to remove any accidental spaces
    ) {
      case "Project No":
        return "projectno";
      case "Project Name":
        return "name"; // Ensure this matches the exact key in your project data
      case "Customer Name":
        return "customer_name";
      case "Budget Hours":
        return "total_planned_hours";
      case "Actual Hours":
        return "actual_hours";
      case "Tags":
        return "tags";
      case "Project Manager":
        return "manager_id";
      case "Start Date":
        return "start_date";
      case "Due Date":
        return "end_date";
      case "Invoice Status":
        return "total_invoice_claimed_phases";
      case "Project Status":
        return "status_name";
      case "Description":
        return "description";
      case "S.No":
        return "index";
      default:
        return "";
    }
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedProjects = [...projects]
    .sort((a, b) => b.id - a.id) // Default to sorting by id in descending order
    .sort((a, b) => {
      if (!sortConfig.key) return 0;

      const key = sortConfig.key;
      const direction = sortConfig.direction === "asc" ? 1 : -1;

      let aValue = a[key];
      let bValue = b[key];

      // Handle specific cases for sorting by displayed values
      switch (key) {
        case "start_date":
        case "end_date":
          aValue = new Date(aValue);
          bValue = new Date(bValue);
          break;
        case "project_manager_name":
        case "customer_name":
          aValue = a[key]?.toLowerCase() || "";
          bValue = b[key]?.toLowerCase() || "";
          break;
        case "budget_hours":
        case "actual_hours":
          aValue = Number(aValue) || 0;
          bValue = Number(bValue) || 0;
          break;
        default:
          if (typeof aValue === "string") aValue = aValue.toLowerCase();
          if (typeof bValue === "string") bValue = bValue.toLowerCase();
          break;
      }

      if (aValue < bValue) return -1 * direction;
      if (aValue > bValue) return 1 * direction;
      return 0;
    });

  const filteredProjects = sortedProjects.filter((project) => {
    return (
      project.name?.toLowerCase().includes(filter) || // Use optional chaining
      project.projectno?.toLowerCase().includes(filter) // Use optional chaining
    );
  });

  const handleSearch = (e) => {
    setFilter(e.target.value.toLowerCase());
  };
  // Calculate total number of pages
  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);

  // Get current projects to display
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };
  const handleFirstPage = () => {
    if (currentPage > 1) setCurrentPage(1);
  };
  const handleLastPage = () => {
    if (currentPage < totalPages) setCurrentPage(totalPages);
  };
  const handleDeleteProject = (e, selectedProject) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setProjecttoDelete((prevSelected) => [...prevSelected, selectedProject]);
    } else {
      setProjecttoDelete((prevSelected) =>
        prevSelected.filter((project) => project.id !== selectedProject.id)
      );
    }
  };

  const renderRowData = (project) => {
    return columns.map((columnKey) => {
      if (visibleColumns[columnKey]) {
        switch (columnKey) {
          case "Project No":
            return (
              <td key="projectNo">
                <Link
                  to={`/project/${project.id}/dashboard`}
                  className="select-project"
                  onClick={() =>
                    localStorage.setItem("activePLink", "dashboard")
                  }
                >
                  {project.projectno}
                </Link>
                {hasPermission("projects", "update") && (
                  <span className="edit-icon-wrapper" title="Edit project">
                    <EditIcon
                      onClick={() => handleEditClick(project)}
                      style={{ backgroundColor: "transparent", color: "blue" }}
                    />
                  </span>
                )}
              </td>
            );
          case "Project Name":
            return <td key="projectName">{project.name}</td>;
          case "Customer Name":
            return <td key="customerName">{project.customer_name}</td>;
          case "Budget Hours":
            return (
              <td key="budgetHours" style={{ textAlign: "center" }}>
                {project.total_planned_hours || 0}
              </td>
            );
          case "Actual Hours":
            return (
              <td key="actualHours" style={{ textAlign: "center" }}>
                {project.total_actual_hours || 0}
              </td>
            );
          case "Tags":
            return <td key="tags">{project.tags}</td>;
          case "Project Manager":
            return <td key="projectManager">{project.project_manager_name}</td>;
          case "Start Date":
            return (
              <td key="startDate">
                {format(project.start_date, "dd-MM-yyyy")}
              </td>
            );
          case "Due Date":
            return (
              <td key="dueDate">{format(project.end_date, "dd-MM-yyyy")}</td>
            );
          case "Invoice Status":
            const claimedPercentage = project.total_phases
              ? (project.total_invoice_claimed_phases / project.total_phases) *
              100
              : 0;
            return (
              <td key="invoiceStatus">
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#e0e0df",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      width: `${claimedPercentage}%`,
                      backgroundColor:
                        claimedPercentage === 100 ? "green" : "orange",
                      height: "10px",
                      borderRadius: "8px",
                    }}
                  ></div>
                </div>
                <span>
                  {project.total_invoice_claimed_phases} of{" "}
                  {project.total_phases} claimed
                </span>
              </td>
            );
          case "Project Status":
            return <td key="projectStatus">{project.status_name}</td>;
          case "Description":
            return <td key="description">{project.description}</td>;
          default:
            return null;
        }
      }
      return null;
    });
  };

  return (
    <div style={{ position: "relative", left: isNavBarOpen ? "0px" : "-80px" }}>
      <Helmet>
        <title>Msuite - Projects</title>
      </Helmet>
      <div className="project-nav-bar-details">
        <h3>Projects</h3>
        <div className="project-tab-details">
          <Link
            className={`proj-links ${activeProjLink === "activeprojects" ? "active" : ""
              }`}
            onClick={() => handleProjLinkClick("activeprojects")}
          >
            Active Projects
          </Link>
          <Link
            className={`proj-links ${activeProjLink === "archivedprojects" ? "active" : ""
              }`}
            onClick={() => handleProjLinkClick("archivedprojects")}
          >
            Archived Projects
          </Link>
        </div>
        <hr className="proj-line" />
      </div>

      {hasPermission("projects", "create") && (
        <>
          <button
            style={{ left: isNavBarOpen ? "91.7%" : "95.85%" }}
            className={
              isNavBarOpen ? "add-proj-heading" : "add-proj-heading-close"
            }
            onClick={onHandleAddProject}
            title="New project"
          >
            New Project
          </button>
        </>
      )}
      {hasPermission("projects", "delete") && projectToDelete.length > 0 && (
        <button
          onClick={() => setDeleteDialogOpen(true)}
          className="delete-proj-heading"
          style={{
            backgroundColor: "red",
            left: isNavBarOpen ? "88%" : "87%",
          }}
          title="Delete project"
        >
          Delete
        </button>
      )}
      {hasPermission("projects", "create") && (
        <div className="upload-project-container">
          <input type="file" accept=".csv" onChange={handleFileChange} />
          <button onClick={handleParse}>Parse CSV</button>
          <a href={sampleCSV} download="sample_projects.csv">
            <button>Download Sample CSV</button>
          </a>
          {csvData.length > 0 && (
            <div>
              <h3>Parsed Projects</h3>
              <pre>{JSON.stringify(csvData, null, 2)}</pre>
              <button onClick={handleUpload}>Upload Projects</button>
            </div>
          )}
        </div>
      )}
      <div
        className={
          isNavBarOpen ? "project-container" : "project-container-close"
        }
      >
        <input
          type="search"
          className="project-search"
          placeholder="Filter by project name or number"
          value={filter}
          onChange={handleSearch}
          title="Search project details"
        />
        <PiColumnsPlusRight
          className="column-icon"
          onClick={toggleFormVisibility}
          title="Add Column"
        />

        <div>
          {/* Button to toggle the form visibility */}

          {/* Conditionally render the form based on isFormVisible */}
          {isFormVisible && (
            <form className="project-column-hide">
              <div className="project-column-hide-container">
                <IoMdClose
                  onClick={toggleFormVisibility}
                  className="close-column-icon"
                />
                {Object.keys(visibleColumns).map((heading) => (
                  <div key={heading} className="header-container">
                    <label>
                      <input
                        type="checkbox"
                        checked={visibleColumns[heading]}
                        onChange={() => toggleColumnVisibility(heading)}
                        style={{ marginRight: "20px" }}
                        disabled={
                          heading === "Project No" || heading === "Project Name"
                        }
                      />
                      {heading}
                    </label>
                  </div>
                ))}
              </div>
            </form>
          )}
        </div>
        <table className="project-content-table" style={{ top: "28px" }}>
          <thead style={{ position: "sticky", top: "-1px", zIndex: "1" }}>
            <tr>
              {hasPermission("projects", "delete") && (
                <th style={{ width: "2px", textAlign: "center" }}>
                  <FaChevronDown />
                </th>
              )}
              {Object.keys(visibleColumns).map((heading, index) =>
                visibleColumns[heading] ? (
                  <th
                    key={heading}
                    style={{
                      width: columnWidths[index],
                      cursor:
                        heading !== "Project No" && heading !== "Project Name"
                          ? "move"
                          : "pointer",
                    }}
                    onClick={() => handleSort(mapKeyToProjectProperty(heading))}
                    onContextMenu={
                      heading !== "Project No" && heading !== "Project Name"
                        ? (e) => handleRightClick(e, heading)
                        : undefined
                    }
                    draggable={
                      heading !== "Project No" && heading !== "Project Name"
                    }
                    onDragStart={
                      heading !== "Project No" && heading !== "Project Name"
                        ? () => handleDragStart(index)
                        : undefined
                    }
                    onDrop={
                      heading !== "Project No" && heading !== "Project Name"
                        ? () => handleDrop(index)
                        : undefined
                    }
                    onDragOver={
                      heading !== "Project No" && heading !== "Project Name"
                        ? (e) => e.preventDefault()
                        : undefined
                    }
                  >
                    <div
                      className="resize-handle"
                      onMouseDown={(e) => handleMouseDown(index, e)}
                    />
                    {heading}
                    {sortConfig.key === mapKeyToProjectProperty(heading) && (
                      <span>
                        {sortConfig.direction === "asc" ? " ↑" : " ↓"}
                      </span>
                    )}
                  </th>
                ) : null
              )}
            </tr>
          </thead>
          <tbody>
            {currentProjects.map((project, index) => {
              const claimedPercentage = project.total_phases
                ? (project.total_invoice_claimed_phases /
                  project.total_phases) *
                100
                : 0;
              return (
                <tr key={project.id}>
                  {/* //visibleColumns.delete && */}
                  {hasPermission("projects", "delete") && (
                    <td>
                      <input
                        type="checkbox"
                        name={project.name}
                        checked={projectToDelete.some(
                          (p) => p.id === project.id
                        )}
                        onChange={(e) => handleDeleteProject(e, project)}
                      />
                    </td>
                  )}
                  {/* Repeat similar structure for other columns */}
                  {renderRowData(project)}
                </tr>
              );
            })}
          </tbody>
        </table>
        {contextMenu.visible && (
          <div
            style={{
              position: "absolute",
              top: "87px",
              left: contextMenu.x,
              backgroundColor: "white",
              boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
              padding: "8px",
              zIndex: 1000,
              display: "flex",
              flexDirection: "column",
            }}
            ref={projectRef}
          >
            <span className="hide-column-span" title="hide-column">
              <GrHide className="hide-icon" />
              <button onClick={hideColumn} className="hide-column">
                Hide Column
              </button>
            </span>
          </div>
        )}
      </div>
      {!isFormVisible && (
        <div
          className={
            userDetails.role_name === "Administrator" ||
              userDetails.role_name === "Project_Manager"
              ? "pagination-controls"
              : "pagination-controls2"
          }
        >
          <h3>Total : {projects.length}</h3>
          <button
            onClick={handleFirstPage}
            disabled={currentPage === 1}
            className="arrow-project-btn"
          >
            <MdKeyboardDoubleArrowLeft title="First Page" />
          </button>
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="arrow-project-btn"
          >
            {/* */}
            <MdKeyboardArrowLeft title="Previous Page" />
          </button>
          <span
            style={{
              marginTop: "16px",
              textWrap: "nowrap",
            }}
          >
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="arrow-project-btn"
          >
            <MdKeyboardArrowRight title="Next Page" />
          </button>
          <button
            onClick={handleLastPage}
            disabled={currentPage === totalPages}
            className="arrow-project-btn"
          >
            <MdKeyboardDoubleArrowRight title="Last Page" />
          </button>
        </div>
      )}
      {openEditDialog && (
        <div className="edit-proj-dial">
          <div class="dialog-edit-content">
            <div className="edit-project-user-header1">
              <h3 class="dialog-title">Edit Project</h3>
            </div>
            <h3 className="edit-proj-label">Project Name</h3>
            <input
              type="text"
              name="name"
              placeholder="Project Name"
              value={updatedProject?.name || ""}
              onChange={handleInputChange}
              className="edit-input"
            />
            <h3 className="edit-proj-label">Project Number</h3>
            <input
              type="text"
              name="projectno"
              placeholder="Project Number"
              value={updatedProject?.projectno || ""}
              onChange={handleInputChange}
              className="edit-input"
            />
            <h3 className="edit-proj-label">Project Manager</h3>
            <select
              className="edit-input"
              name="manager_id" // Add name attribute for handleInputChange
              value={updatedProject?.manager_id || ""} // Ensure the selected manager is reflected
              onChange={handleInputChange} // onChange will now correctly update manager_id
            >
              <option disabled value="">
                Select Manager
              </option>
              {managers
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((manager) => (
                  <option key={manager.id} value={manager.id}>
                    {manager.name}
                  </option>
                ))}
            </select>

            <h3 className="edit-proj-label">Customer Name</h3>
            <input
              type="text"
              name="customer_name"
              placeholder="Customer Name"
              value={updatedProject?.customer_name || ""}
              onChange={handleInputChange}
              className="edit-input"
            />
            <h3 className="edit-proj-label">Tags</h3>
            <input
              type="text"
              name="tags"
              placeholder="Tags"
              value={updatedProject?.tags || ""}
              onChange={handleInputChange}
              className="edit-input"
            />
            <h3 className="edit-proj-label">Start Date</h3>
            <input
              type="date"
              value={
                updatedProject.start_date
                  ? new Date(
                    new Date(updatedProject.start_date).getTime() -
                    new Date().getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .split("T")[0]
                  : ""
              }
              onChange={(event) =>
                handleDateChange(new Date(event.target.value), "start_date")
              }
              className="edit-input"
            // max={
            //   new Date(new Date().getTime() - 0 * 24 * 60 * 60 * 1000) // 7 days in the past
            //     .toISOString()
            //     .split("T")[0]
            // }
            />
            <h3 className="edit-proj-label">End Date</h3>
            <input
              type="date"
              value={
                updatedProject.end_date
                  ? new Date(
                    new Date(updatedProject.end_date).getTime() -
                    new Date().getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .split("T")[0]
                  : ""
              }
              onChange={(event) =>
                handleDateChange(new Date(event.target.value), "end_date")
              }
              // min={
              //   new Date(new Date().getTime() - 0 * 24 * 60 * 60 * 1000) // 7 days in the past
              //     .toISOString()
              //     .split("T")[0]
              // } // Minimum date (optional)
              className="edit-input"
            />
            <h3 className="edit-proj-label">Description</h3>
            <textarea
              className="edit-description"
              name="description"
              placeholder="Description"
              value={updatedProject?.description || ""}
              onChange={handleInputChange}
            />
          </div>
          <div class="edit-proj-btn-con">
            <button
              onClick={handleUpdateProject}
              style={{ marginLeft: "34px" }}
              className="add-btn"
            >
              Save
            </button>
            <button
              onClick={() => setOpenEditDialog(false)}
              className="cancel-btn"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {openDialog && (
        <>
          <div className="add-project-dial">
            <div className="add-new-project-content">
              <div className="add-project-user-header">
                <h3
                  className="add-project-user-heading"
                  style={{ marginLeft: "14px" }}
                >
                  New Project
                </h3>
              </div>
              <div className="project-name-number-container">
                <div className="name-container">
                  <h3 className="box-heading">Project Number</h3>
                  <input
                    className="box-input"
                    placeholder="Enter Project Number"
                    value={newProject.projectno}
                    onChange={(e) =>
                      setNewProject((prev) => ({
                        ...prev,
                        projectno: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="name-container" style={{ marginLeft: "14px" }}>
                  <h3 className="box-heading">Project Name</h3>
                  <input
                    className="box-input"
                    placeholder="Enter Project Name"
                    value={newProject.name}
                    onChange={(e) =>
                      setNewProject((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="start-date-due-date-container">
                <div className="start-dat-container">
                  <h3 className="box-heading">Start date</h3>
                  <DatePicker
                    placeholderText="dd-mm-yyyy"
                    selected={newProject.start_date}
                    onChange={(date) =>
                      setNewProject((prev) => ({
                        ...prev,
                        start_date: date,
                      }))
                    }
                    dateFormat="dd-MM-yyyy"
                    className="box-input"
                    maxDate={newProject.end_date}
                  />
                </div>
                <div className="due-date-container">
                  <h3 className="box-heading">Due date</h3>
                  <DatePicker
                    placeholderText="dd-mm-yyyy"
                    selected={newProject.end_date}
                    onChange={(date) =>
                      setNewProject((prev) => ({
                        ...prev,
                        end_date: date,
                      }))
                    }
                    dateFormat="dd-MM-yyyy"
                    className="box-input"
                    minDate={newProject.start_date}
                  />
                </div>
              </div>
              <div className="project-owner-budget-container">
                <div className="owner-container">
                  <h3 className="box-heading">Owner</h3>
                  <select
                    className="select-d-input"
                    value={newProject.manager_id}
                    onChange={(e) =>
                      setNewProject((prev) => ({
                        ...prev,
                        manager_id: e.target.value,
                      }))
                    }
                  >
                    <option value="" disabled>
                      Select Owner
                    </option>
                    {users
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="name-container" style={{ marginLeft: "14px" }}>
                  <h3 className="box-heading">Tags</h3>
                  <input
                    className="box-input"
                    value={newProject.tags}
                    onChange={(e) =>
                      setNewProject((prev) => ({
                        ...prev,
                        tags: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="add-description-container ">
                <div style={{ backgroundColor: "transparent" }}>
                  <h3 className="box-heading">Add Description</h3>
                  <textarea
                    className="box-des-input"
                    placeholder="Enter Description"
                    value={newProject.description}
                    onChange={(e) =>
                      setNewProject((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }))
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ backgroundColor: "transparent" }}>
                    <h3 className="box-heading">Select Customer</h3>
                    <select
                      className="select-d-input"
                      value={newProject.customer_id}
                      onChange={(e) => {
                        const selectedCustomerId = e.target.value;
                        setNewProject((prev) => ({
                          ...prev,
                          customer_id: selectedCustomerId,
                        }));
                      }}
                    >
                      <option value="" disabled>
                        Select Customer
                      </option>
                      {customers
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((customer) => (
                          <option key={customer.id} value={customer.id}>
                            {customer.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="tags-container">
                    <h3 className="box-heading">Project Group</h3>
                    <select
                      className="select-d-input"
                      style={{ marginTop: "0px", backgroundColor: "white" }}
                      value={newProject.team_id}
                      onChange={(e) => {
                        const selectedGroupId = e.target.value;
                        setNewProject((prev) => ({
                          ...prev,
                          team_id: selectedGroupId,
                        }));
                      }}
                    >
                      <option value="" disabled>
                        Select Group
                      </option>
                      {projectGroup
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((group) => (
                          <option key={group.id} value={group.id}>
                            {group.code}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="project-select-container">
                <div className="project-new-project-container "></div>
              </div>
              <div className="project-details-container">
                <h3 className="box-heading">Project Members</h3>
                <MultiSelect
                  options={members}
                  value={selectedMembers}
                  onChange={setSelectedMembers}
                  className="member-select-option"
                />
              </div>
            </div>
            <div className="add-new-project-button-container">
              <button className="add-btn" onClick={handleAddNewProject}>
                Add
              </button>
              <button onClick={closeDialog} className="cancel-btn">
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
      {deleteDialogOpen && (
        <div className="delete-phase-dial">
          {" "}
          <div className="delete-header">
            <h3 style={{ backgroundColor: "transparent", marginLeft: "15px" }}>
              Delete Project
            </h3>
          </div>
          <div style={{ backgroundColor: "white" }}>
            <p style={{ backgroundColor: "white" }}>
              Are you sure you want to delete this Project? This action cannot
              be undone.
            </p>
            <input
              type="checkbox"
              id="delete-phase"
              checked={isCheckboxChecked}
              onChange={() => setIsCheckboxChecked((prev) => !prev)}
            />
            <label htmlFor="delete-phase" style={{ backgroundColor: "white" }}>
              I understand this action cannot be undone.
            </label>
          </div>
          <div className="delete-dial-btn-con">
            <button
              onClick={handleDeleteConfirm}
              color="secondary"
              variant="contained"
              disabled={!isCheckboxChecked}
              className="add-btn"
              style={{ backgroundColor: isCheckboxChecked ? "red" : "gray" }}
            >
              Delete
            </button>

            <button onClick={handleCloseDial} className="cancel-btn">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectList;
