import React, { useEffect, useState, useContext, useRef } from "react";

import { useRolePermissions } from "../Settings/RoleContext";
import DatePicker from "react-datepicker";
import { IoCheckmarkOutline } from "react-icons/io5";
import { format, isValid } from "date-fns";
import { useParams } from "react-router-dom";
import "../../styles/projects/PhaseDetails.css";
import api from "../../api/axiosConfig";
import { CircularProgress, Typography, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { FaChevronDown } from "react-icons/fa";
import { ProjectContext } from "../../App";
import { Helmet } from "react-helmet";

const fetchData = async (url, setData, setError) => {
  try {
    const response = await fetch(url);
    if (!response.ok)
      throw new Error(`Network response was not ok: ${response.statusText}`);
    const data = await response.json();
    setData(data);
  } catch (error) {
    if (setError) {
      setError(error.message);
    } else {
      console.error("Error fetching data:", error);
    }
  }
};

const addTaskToPhase = async (phaseId, taskTemplateId, setError) => {
  try {
    // Ensure phaseId and taskTemplateId are valid
    if (!phaseId || !taskTemplateId) {
      throw new Error("Invalid phaseId or taskTemplateId");
    }

    // Send a POST request to add the task template to the phase
    const response = await api.post(`/api/addTaskTemplate-phase/${phaseId}`, {
      phase_id: phaseId,
      tasktemplate_id: taskTemplateId,
    });

    // Return the response data
    return response.data;
  } catch (error) {
    // Set error state or handle error as needed
    setError(error.message || "An error occurred while adding the task.");
    // Optionally, you might want to rethrow the error or handle it differently
    throw error;
  }
};

function UpdatePlannedHours({ taskId, initialPlannedHours }) {
  const [plannedHours, setPlannedHours] = useState(initialPlannedHours);
  const [message, setMessage] = useState("");

  const updatePlannedHours = async () => {
    try {
      const response = await api.put(
        `/api/phase_usertasks/${taskId}/plannedhours`,
        {
          plannedhours: plannedHours,
        }
      );
      setMessage(response.data.message);
    } catch (error) {
      setMessage("Failed to update planned hours.");
    }
  };
}

const addVariationToPhase = async (phaseId, variationData, setError) => {
  try {
    const response = await api.post(
      `/api/phase/${phaseId}/addVariation`,
      variationData
    );
    return response.data; // Axios automatically parses JSON
  } catch (error) {
    setError(error);
  }
};
const PhaseDetails = () => {
  const { phaseId, projectId } = useParams();
  const { dialBoxopen, isNavBarOpen } = useContext(ProjectContext);
  const { hasPermission } = useRolePermissions();
  const [phase, setPhase] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [taskTemplateId, setTaskTemplateId] = useState("");
  const [taskTemplates, setTaskTemplates] = useState([]);
  const [tasksByPhase, setTasksByPhase] = useState([]);
  const [selectedTTaskId, setSelectedTTaskId] = useState([]);
  // const [tabIndex, setTabIndex] = useState(0);
  const [tabIndex, setTabIndex] = useState(() => {
    const storedTabIndex = localStorage.getItem("tabIndex");
    return storedTabIndex ? parseInt(storedTabIndex, 10) : 0;
  });
  const [isEditMode, setIsEditMode] = useState(false); // To track if it's add or edit mode
  const [openVariationDialog, setOpenVariationDialog] = useState(false);
  const [variationNo, setVariationNo] = useState("");
  const [variationName, setVariationName] = useState("");
  const [selectedTaskId, setSelectedTaskId] = useState("");
  const [variationHours, setVariationHours] = useState(0);
  const [variations, setVariations] = useState([]); // Initialize as an empty array
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [claimStatusVariation, setClaimStatusVariation] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const variationRef = useRef(null);
  const [bulkActionVariation, setBulkActionVariation] = useState(false);
  const [deleteTaskDialogOpen, setDeleteTaskDialogOpen] = useState(false);
  const [deleteIndividualTaskDialogOpen, setIndividualDeleteTaskDialogOpen] =
    useState(false);
  const [addTaskDial, setAddTaskDial] = useState(false);
  const [taskTemplateIdToAdd, setTaskTemplateIdToAdd] = useState([]);
  const [newTask, setNewTask] = useState({ name: "" });
  const [variationToDelete, setVariationToDelete] = useState([]);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxCheckedForTask, setIsCheckboxCheckedForTask] =
    useState(false);
  useEffect(() => {
    console.log("task template id", tasksByPhase);
  }, [tasksByPhase]);
  //issues props
  const issueRef = useRef(null);
  const [issues, setIssues] = useState([]); // To store the list of issues
  const [openIssueDialog, setOpenIssueDialog] = useState(false); // To control the dialog for adding/editing issues
  const [users, setUsers] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [issueNo, setIssueNo] = useState(""); // To track the issue number
  const [issueName, setIssueName] = useState(""); // To track the issue name
  const [issueHours, setIssueHours] = useState(0); // To track the planned hours for the issue
  const [issueType, setIssueType] = useState([]);
  const [issueRaisedBy, setIssueRaisedBy] = useState([]);
  const [issueErrorBy, setIssueErrorBy] = useState([]);
  const [issueAttendedBy, setIssueAttendedBy] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState(null); // To track the issue being edited
  const [isIssueEditMode, setIsIssueEditMode] = useState(false); // To differentiate between add and edit mode for issues
  const [issueToDelete, setIssueToDelete] = useState([]); // To track the issue to be deleted
  const [deleteIssueDialogOpen, setDeleteIssueDialogOpen] = useState(false); // To control the delete confirmation dialog
  const [isIssueCheckboxChecked, setIsIssueCheckboxChecked] = useState(false); // To track the checkbox state in the delete dialog
  const [bulkAction, setBulkAction] = useState(false);
  const [claimStatusIssue, setClaimStatusIssue] = useState("");
  const [expandedIndex, setExpandedIndex] = useState(null);
  //  add phase_task starts
  const [plannedHours, setPlannedHours] = useState({});
  const [actualHours, setActualHours] = useState({});

  const handleCheckboxChange = (e, taskId) => {
    if (e.target.checked) {
      // Add the taskId to the selectedTTaskId array
      setSelectedTTaskId((prev) => [...prev, taskId]);
    } else {
      // Remove the taskId from the selectedTTaskId array
      setSelectedTTaskId((prev) => prev.filter((id) => id !== taskId));
    }
  };

  // Handle planned hours change
  const handlePlannedHoursChange = (taskId, event) => {
    const value = event.target.value;
    setPlannedHours((prevState) => ({
      ...prevState,
      [taskId]: value,
    }));
  };

  const convertTimeToMinutes = (time) => {
    if (!time) return 0; // Handle null or undefined time values
    const [hours, minutes] = time.split(":").map(Number); // Split into hours and minutes
    return hours * 60 + minutes; // Convert to total minutes
  };

  const convertMinutesToHHMM = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60); // Calculate hours
    const minutes = totalMinutes % 60; // Calculate remaining minutes
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`; // Format as hh:mm
  };

  const handleSavePlannedHours = (taskId) => {
    const hours = plannedHours[taskId];
    console.log("plannedhours is", plannedHours[taskId]);

    // Validate input for HH:mm format
    if (!hours || !/^([0-9]+:[0-5][0-9])$/.test(hours)) {
      alert("Please enter planned hours in HH:mm format.");
      return;
    }

    savePlannedHoursToDatabase(taskId, phaseId, hours).catch((error) => {
      console.error("Error saving planned hours:", error);
      alert(error.message || "An error occurred while saving planned hours.");
    });
  };

  const savePlannedHoursToDatabase = async (taskId, phaseId, hours) => {
    console.log("details:", phaseId, taskId, hours);

    try {
      // Validate inputs again before sending the request
      if (!taskId || !/^([0-9]+:[0-5][0-9])$/.test(hours)) {
        throw new Error(
          "Invalid taskId or hours. Please refresh the page and try again."
        );
      }

      // Send a PUT request to update planned hours
      const response = await api.put(
        `/api/phase_usertasks/${taskId}/plannedhours`,
        {
          plannedhours: hours, // Send HH:mm format
        }
      );
      alert("Hours have been successfully added to the task.");
      console.log("response", response);
      return response.data;
    } catch (error) {
      console.error(
        "Error in savePlannedHoursToDatabase:",
        error.message || error
      );
      throw error; // Re-throw the error to be caught in the calling function
    }
  };

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  // fetch all details based on variation issue
  useEffect(() => {
    const fetchData = async (endpoint, setter) => {
      try {
        const response = await api.get(endpoint);
        setter(response.data);
      } catch (error) {
        setError(error);
      }
    };

    const fetchAllData = async () => {
      try {
        // Fetch all data concurrently
        await Promise.all([
          fetchData(`/api/phase/${phaseId}`, setPhase),
          fetchData(`/api/phase/${phaseId}/usertaskTemplates`, setTasksByPhase),
          fetchData(`/api/taskTemplates`, setTaskTemplates),
          fetchData(`/api/users`, setUsers),
          fetchData(`/api/phase/${phaseId}/variations`, (data) =>
            setVariations(Array.isArray(data) ? data : [])
          ),
          fetchData(`/api/phase/${phaseId}/issues`, (data) =>
            setIssues(Array.isArray(data) ? data : [])
          ),
          api.get(`/api/phase/${phaseId}/plannedHours`, (data) =>
            setPlannedHours(Array.isArray(data) ? data : [])
          ),
          fetchData(`/api/projects/${projectId}/customer`, setCustomer),
        ]);
      } finally {
        setLoading(false); // Ensure loading is set to false once all requests are done
      }
    };

    fetchAllData();
  }, [phaseId]);

  useEffect(() => {
    console.log("tasksByPhase", tasksByPhase);
  }, [tasksByPhase]);

  useEffect(() => {
    console.log("phase data", phase);
  }, [phase]);

  //handleAddTask
  const handleAddTask = async () => {
    console.log("task by phase before inserting new value", tasksByPhase);

    if (!taskTemplateId) {
      alert("Please select a task to add.");
      return;
    }

    try {
      const newTask = await addTaskToPhase(
        phaseId,
        Number(taskTemplateId),
        setError
      );
      console.log("new task--", newTask);
      if (newTask) {
        // Update tasks for the phase
        setTasksByPhase((prevTasks) => {
          const updatedTasks = [...prevTasks, newTask];
          console.log("new task added", updatedTasks);
          return updatedTasks;
        });
        console.log("task by phase after inserting new value", tasksByPhase);
        // Instead of removing the taskTemplateId, mark it as "used"
        setTaskTemplates((prevTemplates) =>
          prevTemplates.map((template) =>
            template.id === Number(taskTemplateId)
              ? { ...template, used: true } // Mark template as used instead of removing it
              : template
          )
        );

        // Clear the task template selection
        setTaskTemplateId("");
        // Clear any existing errors
        setError(null);
      }
    } catch (error) {
      console.error("Error adding task:", error);
    }
  };
  const handleDeleteTask = async () => {
    setIndividualDeleteTaskDialogOpen(true);
  };

  const handleDeleteClick = (taskTemplateId) => {
    console.log("task id to delete", taskTemplateId);
    setTaskToDelete(Number(taskTemplateId));
    setDeleteTaskDialogOpen(true);
  };

  const handleDeleteTaskTemplate = async () => {
    const taskTemplateId = Number(taskToDelete);

    if (!taskTemplateId) {
      console.error("No task template ID set for deletion.");
      alert("Please select a task template to delete.");
      return;
    }

    try {
      // Perform the deletion via API
      const response = await api.delete(
        `/api/phase/${phaseId}/usertaskTemplates/${taskTemplateId}`
      );

      if (response.status === 200) {
        // Update state to reflect the deletion
        setTasksByPhase((prevTasks) =>
          prevTasks.filter((task) => task.id !== taskTemplateId)
        );
        setTaskTemplates((prevTemplates) =>
          prevTemplates.map((template) =>
            template.id === taskTemplateId
              ? { ...template, used: false }
              : template
          )
        );
        setDeleteTaskDialogOpen(false);
        setIsCheckboxChecked(false);
        console.log("Task template deleted successfully.");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorMessage =
          error.response.data?.message || "An error occurred.";
        console.error("Deletion prevented:", errorMessage);
        alert(errorMessage); // Show alert for timesheet conflict
      } else {
        console.error("Unexpected error during deletion:", error.message);
        alert("An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleDeleteAssociatedTask = async () => {
    if (selectedTTaskId.length > 0) {
      console.log("selected task id's", selectedTTaskId);
      try {
        // Make the API request to delete the selected tasks
        const response = await api.delete("/api/deletetasks", {
          data: { taskIds: selectedTTaskId }, // Send taskIds as an array
        });
        setSelectedTTaskId([]);
        setIndividualDeleteTaskDialogOpen(false);
        setIsCheckboxCheckedForTask(false);
        console.log(response.data.message); // Log success message or alert it to the user
        setTasksByPhase((prevTasksByPhase) => {
          return prevTasksByPhase.map((phase) => {
            // Filter out tasks that have been deleted
            const updatedTasks = phase.tasks.filter(
              (task) => !selectedTTaskId.includes(task.id)
            );

            // Return the updated phase object with the remaining tasks
            return {
              ...phase,
              tasks: updatedTasks,
            };
          });
        });
      } catch (error) {
        console.error("Error details:", error);
        console.error("Error response:", error.response);
        // Optionally, show a user-friendly error message
        alert(
          error.response?.data?.message ||
            "An error occurred while deleting tasks."
        );
      }
    } else {
      console.log("No tasks selected for deletion.");
      alert("Please select at least one task to delete.");
    }
  };
  const handleAddNewTask = async () => {
    try {
      // Check if the role name is not empty
      if (!newTask.name.trim()) {
        alert("Task name cannot be empty");
        return;
      }

      // Send POST request to create a new role
      const response = await api.post(
        `/api/addusertasks/${taskTemplateIdToAdd}/${phaseId}`,
        {
          name: newTask.name,
        }
      );

      // Handle the response
      if (response.status === 201) {
        const newTask = response.data; // Example: { id: 101, name: 'New Task', phase_id: '213' }

        // Update tasksByPhase state to include the new task in the correct phase
        setTasksByPhase((prevTasksByPhase) => {
          return prevTasksByPhase.map((phase) => {
            if (String(phase.id) === String(taskTemplateIdToAdd)) {
              // Add the new task to the "tasks" array of the matching phase
              return {
                ...phase,
                tasks: [...phase.tasks, newTask],
              };
            }
            return phase; // Return unchanged phase if not matched
          });
        });
        alert("Task added successfully!");
        setAddTaskDial(false);
        setNewTask({ name: "" }); // Clear the input field
      }
    } catch (error) {
      console.error("Failed to add new role:", error);
      alert("Failed to add task. Please try again.");
    }
  };

  const handleTabChange = (index) => {
    setTabIndex(index);
    localStorage.setItem("tabIndex", index);
  };

  const handleOpenVariationDialog = () => {
    const suggestedVariationNo = `VO-${String(
      (variations?.length || 0) + 1
    ).padStart(2, "0")}`;
    setVariationNo(suggestedVariationNo);
    setVariationName(`${phase.phaseno}-${suggestedVariationNo}`);
    setSelectedTaskId("");
    setVariationHours(0);
    setIsEditMode(false);
    setOpenVariationDialog(true);
  };
  const handleOpenEditVariationDialog = (variation) => {
    setIsEditMode(true);
    setSelectedVariation(variation);
    setVariationNo(variation.variationno);
    setVariationName(variation.name);
    setSelectedTaskId(variation.taskId);
    setVariationHours(variation.plannedhours);
    setOpenVariationDialog(true);
  };
  const handleAddVariation = async () => {
    const newVariation = {
      variationNo,
      variationName,
      taskId: selectedTaskId,
      variationHours,
      projectId: phase.project_id, // Assuming phase has a project_id field
    };
    const addedVariation = await addVariationToPhase(
      phaseId,
      newVariation,
      setError
    );
    if (addedVariation) {
      setVariations((prev) => [...prev, addedVariation]);
      setOpenVariationDialog(false);
      setSelectedTaskId(""); // Reset selected task
      setVariationHours(0); // Reset variation hours
    }
  };

  const handleDeleteVariation = (e, variationId) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      // Add phaseId to the selectedPhaseId array
      setVariationToDelete((prev) => [...prev, variationId]);
    } else {
      // Remove phaseId from the selectedPhaseId array
      setVariationToDelete((prev) => prev.filter((id) => id !== variationId));
    }
  };

  //delete variation
  const handleDeleteConfirm = async () => {
    if (variationToDelete.length > 0) {
      try {
        // Send the array of variation IDs to the backend
        await api.delete(`/api/phase/${phaseId}/variations`, {
          data: { variationIds: variationToDelete },
        });

        // Update the state to remove the deleted variations
        setVariations((prevVariations) =>
          prevVariations.filter(
            (variation) => !variationToDelete.includes(variation.id)
          )
        );
      } catch (error) {
        console.error("Error deleting variations:", error);
      } finally {
        setBulkActionVariation(false);
        setDeleteDialogOpen(false);
        setIsCheckboxChecked(false);
        setVariationToDelete([]); // Reset the variations to delete
      }
    } else {
      alert("Please select at least one variation to delete.");
    }
  };

  // Handle save or add variation
  const handleSaveOrAddVariation = () => {
    if (isEditMode) {
      handleSaveVariation();
    } else {
      handleAddVariation();
    }
  };

  const handleSaveVariation = async () => {
    try {
      const updatedVariation = {
        ...selectedVariation,
        name: variationName,
        plannedhours: variationHours,
        task_id: selectedTaskId,
      };
      const response = await api.put(
        `/api/phase/${phaseId}/variation/${selectedVariation.id}`,
        updatedVariation
      );
      if (response.status === 200) {
        // Axios uses status codes for response validation
        setVariations(
          variations.map((v) =>
            v.id === selectedVariation.id ? updatedVariation : v
          )
        );
        setOpenVariationDialog(false);
      } else {
        console.error("Error updating variation:", response.statusText);
      }
    } catch (error) {
      console.error("Error saving variation:", error);
    }
  };

  const updateClaimStatus = async (variationId, newValue, fieldType) => {
    try {
      // Check if the status is being changed to "Unclaimed"
      if (fieldType === "status" && newValue === "Unclaimed") {
        // Show a confirmation popup
        const confirmUnclaim = window.confirm(
          "Are you sure you want to change the status to Unclaimed?"
        );

        // If user clicks "No", return without making changes
        if (!confirmUnclaim) {
          return; // No changes, exit function
        }
      }

      let requestData = {};

      // Determine the type of update based on the `fieldType`
      if (fieldType === "status") {
        requestData = { status: newValue }; // Update only the status
      } else if (fieldType === "date") {
        requestData = { date: newValue ? newValue.toISOString() : null }; // Update only the date
      } else if (fieldType === "number") {
        requestData = { invoice_no: newValue }; // Update the invoice number
      } else {
        console.error("Invalid field type for updateClaimStatus.");
        return;
      }

      // Send the request to the backend
      const response = await api.put(
        `/api/phase/${variationId}/approval`,
        requestData
      );

      if (response.status === 200) {
        const updatedFields = {};
        if (requestData.status)
          updatedFields.invoice_status = requestData.status;
        if (requestData.date) updatedFields.invoice_date = requestData.date;
        if (requestData.invoice_no)
          updatedFields.invoice_no = requestData.invoice_no;

        // Update the state with the new variation data
        setVariations((prev) =>
          prev.map((v) =>
            v.id === variationId ? { ...v, ...updatedFields } : v
          )
        );

        // Optionally fetch updated data
        fetchData();
      } else {
        console.error(
          "Failed to update variation:",
          response.statusText || "No status text"
        );
      }
    } catch (error) {
      console.error("Error updating variation:", error);
    }
  };

  const updateBulkVariationClaimStatus = async (variationIds, newStatus) => {
    try {
      // If the new status is "Unclaimed", show confirmation
      if (newStatus === "Unclaimed") {
        const confirmUnclaim = window.confirm(
          "Are you sure you want to change the status of selected phases to Unclaimed?"
        );

        // If user clicks "No", stop the function
        if (!confirmUnclaim) {
          return; // Exit without making changes
        }
      }

      // Send the bulk update request to the backend
      const response = await api.put("/api/variation/bulk-approval", {
        variationIds,
        status: newStatus,
      });

      console.log("Bulk update response:", response.data);

      if (response.status === 200) {
        // Show success message
        alert(response.data.message);
        setBulkActionVariation(false);
        setIsCheckboxChecked(false);
        setVariationToDelete([]);
        // Update the variations state directly based on the bulk update
        setVariations((prev) =>
          prev.map((v) =>
            variationIds.includes(v.id)
              ? { ...v, invoice_status: newStatus } // Update the status of the modified variations
              : v
          )
        );

        // Optionally fetch updated data
        fetchData(); // If the API updates more fields, you can call this function to refresh the data
      } else {
        // Handle other cases (e.g., no rows updated)
        alert(response.data.message || "No variations updated");
      }
    } catch (error) {
      console.error("Failed to update bulk statuses", error);
      alert(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        variationRef.current &&
        !variationRef.current.contains(event.target)
      ) {
        setBulkActionVariation(false);
      }
      if (issueRef.current && !issueRef.current.contains(event.target)) {
        setBulkAction(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
  }, []);

  //issues
  const addIssueToPhase = async (phaseId, issueData, setError) => {
    try {
      const response = await api.post(
        `/api/phase/${phaseId}/addIssue`,
        issueData
      );
      // No need to manually parse JSON; Axios does that automatically
      return response.data;
    } catch (error) {
      setError(error);
    }
  };

  const handleAddIssue = async () => {
    const newIssue = {
      issueNo,
      issueName,
      issueType,
      issueRaisedBy,
      issueErrorBy,
      issueAttendedBy,
      // taskId: selectedTaskId,
      projectId: phase.project_id, // Assuming phase has a project_id field
    };
    const addedIssue = await addIssueToPhase(phaseId, newIssue, setError);
    if (addedIssue) {
      setIssues((prev) => [...prev, addedIssue]);
      setOpenIssueDialog(false);
      setSelectedTaskId(""); // Reset selected task
      setIssueHours(0); // Reset issue hours
    }
  };

  const handleOpenIssueDialog = () => {
    const suggestedIssueNo = `IS-${String((issues?.length || 0) + 1).padStart(
      2,
      "0"
    )}`;
    setIssueNo(suggestedIssueNo);
    setIssueName(`${phase.phaseno}-${suggestedIssueNo}`);
    setSelectedTaskId("");
    setIssueHours(0);
    setIsIssueEditMode(false);
    setOpenIssueDialog(true);
  };

  const handleOpenEditIssueDialog = (issue) => {
    setIsIssueEditMode(true);
    setSelectedIssue(issue);
    setIssueNo(issue.issueno);
    setIssueName(issue.name);
    setSelectedTaskId(issue.taskId);
    setIssueHours(issue.issuehours);
    setOpenIssueDialog(true);
  };

  const handleSaveIssue = async () => {
    try {
      const updatedIssue = {
        ...selectedIssue,
        name: issueName,
        issuehours: issueHours,
        task_id: selectedTaskId,
      };

      const response = await api.put(
        `/api/phase/${phaseId}/issue/${selectedIssue.id}`,
        updatedIssue
      );

      if (response.status === 200) {
        // Axios does not throw on non-2xx responses, check status manually
        setIssues(
          issues.map((issue) =>
            issue.id === selectedIssue.id ? updatedIssue : issue
          )
        );

        setOpenIssueDialog(false);
      } else {
        console.error("Error updating issue:", response.statusText);
      }
    } catch (error) {
      console.error("Error saving issue:", error);
    }
  };

  const handleDeleteIssue = (e, issueId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add issueId to the state array
      setIssueToDelete((prev) => [...prev, issueId]);
    } else {
      // Remove issueId from the state array if unchecked
      setIssueToDelete((prev) => prev.filter((id) => id !== issueId));
    }
  };

  // Delete issue
  const handleDeleteIssueConfirm = async () => {
    if (issueToDelete.length > 0) {
      try {
        // Send the array of issue IDs to the backend for deletion
        await api.delete(`/api/phase/${phaseId}/issues`, {
          data: { issueIds: issueToDelete },
        });

        // Update the state to remove the deleted issues
        setIssues((prevIssues) =>
          prevIssues.filter((issue) => !issueToDelete.includes(issue.id))
        );
      } catch (error) {
        console.error("Error deleting issues:", error);
      } finally {
        setDeleteIssueDialogOpen(false);
        setIsIssueCheckboxChecked(false); // Reset the checkbox state
        setIssueToDelete([]); // Clear the selected issues after deletion
      }
    } else {
      alert("Please select at least one issue to delete.");
    }
  };

  const updateIssueClaimStatus = async (issueId, newStatus) => {
    try {
      // Make the API call to update the status
      const response = await api.put(`/api/phase/${issueId}/approved`, {
        status: newStatus,
      });

      // Assuming the response contains the updated issue data
      const updatedStatus = {
        id: issueId,
        invoice_status: newStatus, // Update status here
        // If you have other fields to update, include them here.
      };

      // Update the issues state by mapping through and updating the relevant issue
      setIssues((prev) =>
        prev.map((issue) =>
          issue.id === issueId ? { ...issue, ...updatedStatus } : issue
        )
      );
    } catch (error) {
      // Handle errors
      if (error.response) {
        console.error("Response status:", error.response.status);
        console.error("Response data:", error.response.data);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };

  const updateBulkIssueClaimStatus = async (issueIds, newStatus) => {
    try {
      const response = await api.put("/api/issue/bulk-approval", {
        issueIds,
        status: newStatus,
      });
      console.log("issue id", issueIds);
      if (response.status === 200) {
        alert(response.data.message); // Show success message
        // Optionally, refresh data
      } else {
        alert(response.data.message); // Handle other cases (e.g., no rows updated)
      }
    } catch (error) {
      console.error("Failed to update bulk statuses", error);
      alert(error.response?.data?.message || "An error occurred");
    }
  };

  if (loading)
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  if (error)
    return (
      <p color="error" className="error-message">
        Error: {error.message}
      </p>
    );
  if (!phase) return <Typography>No phase found</Typography>;

  const totalVariationHours = variations.reduce(
    (total, variation) => total + (variation.plannedhours || 0),
    0
  );
  const totalIssueHours = issues.reduce(
    (total, issue) => total + (issue.issuehours || 0),
    0
  );
  const allUnclaimed = variations.every(
    (variation) => variation.invoice_status === "Unclaimed"
  );
  const allIssuesUnclaimed = issues.every(
    (issue) => issue.invoice_status === "Unclaimed"
  );
  return (
    <>
      <Helmet>
        <title>Msuite - Projects</title>
      </Helmet>
      {!dialBoxopen && (
        <div className={isNavBarOpen ? "phase-details" : "phase-details-close"}>
          <div
            style={{
              marginTop: isNavBarOpen ? "0px" : "126px",
              marginLeft: isNavBarOpen ? "45px" : "0px",
              backgroundColor: "transparent",
            }}
          >
            <div style={{ display: "flex" }}>
              <h3 className="phase-title">Phase Title : {phase.title}</h3>
              <h3 className="phase-title">Phase No : {phase.phaseno}</h3>
              <h3 className="phase-title">
                Planned Hours : {phase.total_planned_hours}
              </h3>
            </div>
            <div className="tabs">
              <button
                onClick={() => handleTabChange(0)}
                className={`tab-button ${tabIndex === 0 ? "active" : ""}`}
              >
                General
              </button>
              <button
                onClick={() => handleTabChange(1)}
                className={`tab-button ${tabIndex === 1 ? "active" : ""}`}
              >
                Variations
              </button>
              <button
                onClick={() => handleTabChange(2)}
                className={`tab-button ${tabIndex === 2 ? "active" : ""}`}
              >
                Issues
              </button>
            </div>
            {tabIndex === 0 && (
              <div>
                {hasPermission("projects", "update") && (
                  <>
                    {taskTemplates.some(
                      (template) =>
                        !tasksByPhase.some((task) => task.id === template.id)
                    ) ? (
                      <>
                        <select
                          label="Task Template"
                          value={taskTemplateId}
                          style={{ backgroundColor: "white", padding: "10px" }}
                          onChange={(e) => setTaskTemplateId(e.target.value)}
                          displayEmpty
                          fullWidth
                        >
                          <option value="" disabled>
                            Select a task template
                          </option>
                          {taskTemplates
                            .filter(
                              (template) =>
                                !tasksByPhase.some(
                                  (task) => task.id === template.id
                                )
                            ) // Filtering unassociated templates
                            .map((template) => (
                              <option key={template.id} value={template.id}>
                                {template.name}
                              </option>
                            ))}
                        </select>
                        <button
                          onClick={handleAddTask}
                          variant="contained"
                          color="primary"
                          fullWidth
                          style={{
                            marginTop: "16px",
                            textWrap: "nowrap",
                            width: "auto",
                          }}
                          className="add-btn"
                          title="Add Task"
                        >
                          Add Task Template
                        </button>
                      </>
                    ) : (
                      <p>All tasks are selected</p>
                    )}
                  </>
                )}
                {/* Display associated tasks */}
                <div style={{ display: "flex" }}>
                  <h3 className="task-name">Associated Tasks:</h3>
                  {hasPermission("projects", "delete") &&
                    selectedTTaskId.length !== 0 && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent toggle from firing
                          handleDeleteTask();
                        }}
                        className="add-user-task-btn"
                        title="Delete Task"
                      >
                        {/* <DeleteIcon style={{ color: "red" }} /> */}
                        Delete Task
                      </button>
                    )}
                </div>

                <div>
                  {tasksByPhase.length > 0 ? (
                    tasksByPhase.map((taskTemplate, index) => (
                      <div key={taskTemplate.id} className="accordion">
                        <div
                          className="accordion-summary"
                          style={{ display: "flex", fontWeight: "normal" }}
                          onClick={() => handleToggle(index)}
                        >
                          <h6 className="phase-table-title">
                            {taskTemplate.name}
                          </h6>

                          {hasPermission("projects", "delete") && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent toggle from firing
                                handleDeleteClick(taskTemplate.id);
                              }}
                              style={{
                                marginLeft: "auto",
                                marginRight: "10px",
                                border: "none",
                                cursor: "pointer",
                              }}
                              title="Delete Task Template"
                            >
                              <DeleteIcon style={{ color: "red" }} />
                            </button>
                          )}
                        </div>
                        {/* {expandedIndex === index && ( */}
                        <div className="accordion-details">
                          <table className="accordion-table">
                            <thead>
                              <tr>
                                {hasPermission("projects", "delete") && (
                                  <th style={{ width: "2px" }}>
                                    <FaChevronDown />
                                  </th>
                                )}
                                <th className="task-name">Task Name</th>
                                <th className="planned-hours">Planned Hours</th>
                                <th className="actual-hours">Actual Hours</th>
                                <th className="actual-hours">Difference</th>
                              </tr>
                            </thead>
                            <tbody>
                              {taskTemplate.tasks &&
                                taskTemplate.tasks.map((task) => (
                                  <tr key={task.id}>
                                    {hasPermission("projects", "delete") && (
                                      <td style={{ textAlign: "center" }}>
                                        <input
                                          type="checkbox"
                                          name={task.name}
                                          checked={selectedTTaskId.includes(
                                            task.id
                                          )}
                                          onChange={(e) =>
                                            handleCheckboxChange(e, task.id)
                                          }
                                        />
                                      </td>
                                    )}
                                    <td>{task.name}</td>
                                    <td style={{ textAlign: "center" }}>
                                      <>
                                        <input
                                          type="text"
                                          defaultValue={task.planned_hours}
                                          onChange={(e) =>
                                            handlePlannedHoursChange(task.id, e)
                                          }
                                          placeholder={
                                            "Enter planned hours (e.g., 50:25)"
                                          }
                                          className="planned-hous-phase-input"
                                          disabled={
                                            !hasPermission("projects", "update")
                                          }
                                          style={{
                                            cursor: hasPermission(
                                              "projects",
                                              "update"
                                            )
                                              ? "pointer"
                                              : "not-allowed",
                                          }}
                                        />
                                        <button
                                          onClick={() =>
                                            handleSavePlannedHours(task.id)
                                          }
                                          className="phase-save-btn"
                                          disabled={
                                            !hasPermission("projects", "update")
                                          }
                                          style={{
                                            cursor: hasPermission(
                                              "projects",
                                              "update"
                                            )
                                              ? "pointer"
                                              : "not-allowed",
                                          }}
                                        >
                                          <IoCheckmarkOutline className="tick-tick" />
                                        </button>
                                      </>
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <input
                                        type="text"
                                        defaultValue={task.actualhours}
                                        disabled
                                        placeholder="Actual hours"
                                        className="planned-hous-phase-input"
                                        style={{ cursor: "not-allowed" }}
                                      />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {task.planned_hours && task.actualhours
                                        ? convertMinutesToHHMM(
                                            convertTimeToMinutes(
                                              task.planned_hours
                                            ) -
                                              convertTimeToMinutes(
                                                task.actualhours
                                              )
                                          )
                                        : ""}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {hasPermission("projects", "update") && (
                            <button
                              className="add-btn"
                              onClick={() => {
                                setAddTaskDial(true);
                                setTaskTemplateIdToAdd(taskTemplate.id);
                              }}
                            >
                              Add Task
                            </button>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No tasks associated with this phase.</p>
                  )}
                </div>
              </div>
            )}
            {deleteTaskDialogOpen && (
              <div className="delete-var-issue-dial">
                <div className="delete-var-header">
                  <h3 className="delete-variation">Delete Task Template</h3>
                </div>
                <div style={{ backgroundColor: "white" }}>
                  <p style={{ backgroundColor: "white", marginLeft: "10px" }}>
                    Are you sure you want to delete this Task Template? This
                    action cannot be undone.
                  </p>
                  <input
                    type="checkbox"
                    checked={isCheckboxChecked}
                    id="tick"
                    onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                  />
                  <label
                    htmlFor="tick"
                    style={{ backgroundColor: "white", userSelect: "none" }}
                  >
                    I understand this action cannot be undone.
                  </label>
                </div>
                <div className="btn-var-del-con">
                  <button
                    onClick={handleDeleteTaskTemplate}
                    color="secondary"
                    variant="contained"
                    disabled={!isCheckboxChecked}
                    style={{
                      backgroundColor: isCheckboxChecked ? "red" : "grey",
                    }}
                    className="add-btn"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => {
                      setDeleteTaskDialogOpen(false);
                      setIsCheckboxChecked(false);
                    }}
                    className="cancel-btn"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            {deleteIndividualTaskDialogOpen && (
              <div className="delete-var-issue-dial">
                <div className="delete-var-header">
                  <h3 className="delete-variation">Delete Task</h3>
                </div>
                <div style={{ backgroundColor: "white" }}>
                  <p style={{ backgroundColor: "white", marginLeft: "10px" }}>
                    Are you sure you want to delete this Task? This action
                    cannot be undone.
                  </p>
                  <input
                    type="checkbox"
                    checked={isCheckboxCheckedForTask}
                    id="tick"
                    onChange={() =>
                      setIsCheckboxCheckedForTask(!isCheckboxCheckedForTask)
                    }
                  />
                  <label
                    htmlFor="tick"
                    style={{ backgroundColor: "white", userSelect: "none" }}
                  >
                    I understand this action cannot be undone.
                  </label>
                </div>
                <div className="btn-var-del-con">
                  <button
                    onClick={handleDeleteAssociatedTask}
                    color="secondary"
                    variant="contained"
                    disabled={!isCheckboxCheckedForTask}
                    style={{
                      backgroundColor: isCheckboxCheckedForTask
                        ? "red"
                        : "grey",
                    }}
                    className="add-btn"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => {
                      setIndividualDeleteTaskDialogOpen(false);
                      setIsCheckboxCheckedForTask(false);
                    }}
                    className="cancel-btn"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            {addTaskDial && (
              <div className="add-role-dial">
                <div className="add-role-settings">
                  <div className="add-role-header">
                    <h3 className="add-new-user-heading">Add Task</h3>
                  </div>
                  <h3 className="label-header-element">Task Name</h3>
                  <input
                    className="user-element"
                    placeholder="Enter Task Name"
                    type="text"
                    value={newTask.name}
                    onChange={(e) => setNewTask({ name: e.target.value })}
                  />
                  <div className="task-btn-container">
                    <button className="add-btn" onClick={handleAddNewTask}>
                      Add
                    </button>
                    <button
                      className="cancel-btn"
                      onClick={() => {
                        setAddTaskDial(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            {/*Variation tab*/}
            {tabIndex === 1 && (
              <Box>
                <>
                  {variationToDelete.length > 0 && (
                    <button
                      onClick={() => setBulkActionVariation((prev) => !prev)}
                      className="add-btn mass-update-var-issue"
                    >
                      Mass Update
                    </button>
                  )}
                  {/* {hasPermission("projects", "delete") &&
                    variationToDelete.length > 0 && (
                      <button
                        className="add-btn add-issue-btn delete-var-issue-btn"
                        onClick={() => setDeleteDialogOpen(true)}
                        title="Delete Variation"
                      >
                        Delete
                      </button>
                    )} */}
                  {hasPermission("projects", "create") && (
                    <button
                      onClick={handleOpenVariationDialog}
                      className="add-btn variation-btn"
                      title="New Variation"
                    >
                      New Variation
                    </button>
                  )}
                </>

                {variations.length === 0 && (
                  <div className="phase-image-container">
                    <img
                      className="phase-image-variations"
                      src="/AppLayout/variation.png"
                    />
                    <p className="phase-message-variations">
                      No variation available for this project
                    </p>
                  </div>
                )}

                {variations.length !== 0 && (
                  <div
                    className={
                      isNavBarOpen
                        ? "variation-table-container"
                        : "variation-table-container-close"
                    }
                  >
                    <table className="variation-table">
                      <thead>
                        <tr>
                          {hasPermission("projects", "delete") && (
                            <th style={{ width: "2px" }}>
                              <FaChevronDown />
                            </th>
                          )}

                          <th>Variation Number</th>
                          <th>Variation Name</th>
                          <th>Planned Hours</th>
                          <th>Actual Hours</th>
                          <th style={{ width: "10px" }}>Claim Status</th>
                          {!allUnclaimed && (
                            <>
                              <th style={{ width: "200px" }}>Invoice Number</th>
                              <th>Invoice Date</th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {variations.map((variation, index) => (
                          <tr key={index}>
                            {hasPermission("projects", "delete") && (
                              <td>
                                <input
                                  type="checkbox"
                                  name={variation.name}
                                  checked={variationToDelete.includes(
                                    variation.id
                                  )}
                                  onChange={(e) =>
                                    handleDeleteVariation(e, variation.id)
                                  }
                                />
                              </td>
                            )}
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span style={{ marginTop: "6px" }}>
                                  {variation.variationno}
                                </span>
                                {hasPermission("projects", "update") && (
                                  <button
                                    style={{
                                      width: "50px",
                                      border: "none",
                                      paddingRight: "50px",
                                      color: "#0000ff",
                                      backgroundColor: "transparent",
                                    }}
                                    onClick={() =>
                                      handleOpenEditVariationDialog(variation)
                                    }
                                    className="edit-icon-wrapper"
                                    title="Edit Variation"
                                  >
                                    <EditIcon />
                                  </button>
                                )}
                              </div>
                            </td>{" "}
                            <td>{variation.name}</td>
                            <td style={{ width: "10px", textAlign: "center" }}>
                              {variation.plannedhours}
                            </td>
                            <td style={{ width: "10px", textAlign: "center" }}>
                              {variation.actualhours}
                            </td>
                            <td
                              style={{
                                backgroundColor:
                                  variation.invoice_status === "Claimed"
                                    ? "#a3d6a7"
                                    : "#d2b48c",
                                color: "white",
                                width: "140px",
                                textAlign: "center",
                                fontWeight: "550",
                              }}
                            >
                              <select
                                style={{
                                  border: "none",
                                  backgroundColor:
                                    variation.invoice_status === "Claimed"
                                      ? "#a3d6a7"
                                      : "#d2b48c",
                                  color: "white",
                                  paddingLeft: "38px",
                                  paddingRight: "28px",
                                  fontWeight: "550",
                                  cursor: !hasPermission("projects", "update")
                                    ? "not-allowed"
                                    : "",
                                }}
                                onChange={
                                  (e) =>
                                    updateClaimStatus(
                                      variation.id,
                                      e.target.value,
                                      "status"
                                    ) // Update only the status
                                }
                                value={variation.invoice_status}
                                disabled={!hasPermission("projects", "update")}
                              >
                                <option
                                  style={{
                                    backgroundColor: "white",
                                    color: "black",
                                    fontWeight: "550",
                                  }}
                                  value="Unclaimed"
                                >
                                  Unclaimed
                                </option>
                                <option
                                  style={{
                                    backgroundColor: "white",
                                    color: "black",
                                    fontWeight: "550",
                                  }}
                                  value="Claimed"
                                >
                                  Claimed
                                </option>
                              </select>
                            </td>
                            {!allUnclaimed && (
                              <>
                                <td>
                                  <input
                                    value={variation.invoice_no}
                                    className="invoice-element"
                                    placeholder="Enter Invoice Number"
                                    type="text"
                                    onChange={
                                      (e) =>
                                        updateClaimStatus(
                                          variation.id,
                                          e.target.value,
                                          "number"
                                        ) // Update only the date
                                    }
                                    style={{
                                      cursor: !hasPermission(
                                        "projects",
                                        "update"
                                      )
                                        ? "not-allowed"
                                        : "",
                                    }}
                                    disabled={
                                      !hasPermission("projects", "update")
                                    }
                                  />
                                </td>
                                <td>
                                  <DatePicker
                                    onChange={
                                      (date) =>
                                        updateClaimStatus(
                                          variation.id,
                                          date,
                                          "date"
                                        ) // Update only the date
                                    }
                                    selected={
                                      variation.invoice_date &&
                                      isValid(new Date(variation.invoice_date))
                                        ? new Date(variation.invoice_date)
                                        : null
                                    }
                                    disabled={
                                      variation.invoice_status === "Unclaimed"
                                    }
                                    placeholderText="Select Date"
                                    className="invoice-input"
                                    dateFormat="dd-MM-yyyy"
                                  />
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="variation-summary">
                      <p className="variation-para-hours">
                        Total Variation Hours: {totalVariationHours}
                      </p>
                      <p className="variation-para">
                        Total Number of Variations: {variations.length}
                      </p>
                    </div>
                  </div>
                )}
                {bulkActionVariation && (
                  <div className="action-box-variation" ref={variationRef}>
                    {hasPermission("projects", "delete") &&
                      variationToDelete.length > 0 && (
                        <button
                          className="add-btn"
                          onClick={() => setDeleteDialogOpen(true)}
                          title="Delete Variation"
                          style={{ backgroundColor: "red" }}
                        >
                          Delete
                        </button>
                      )}
                    {hasPermission("projects", "update") && (
                      <button
                        onClick={() => setClaimStatusVariation((prev) => !prev)}
                        className="add-btn"
                        style={{ textWrap: "nowrap" }}
                      >
                        Claim Status
                      </button>
                    )}
                    {claimStatusVariation &&
                      hasPermission("projects", "update") && (
                        <select
                          onChange={(e) =>
                            updateBulkVariationClaimStatus(
                              variationToDelete,
                              e.target.value
                            )
                          }
                          className="bulk-select"
                        >
                          <option>Select Claim Status</option>
                          <option>Claimed</option>
                          <option>Unclaimed</option>
                        </select>
                      )}
                  </div>
                )}
                {/*add variation dial box*/}
                {openVariationDialog && (
                  <div className="add-variation-dial">
                    <div className="dial-v-header">
                      <h3 className="add-edit-variation">
                        {isEditMode ? "Edit Variation" : "Add Variation"}
                      </h3>
                    </div>
                    <div className="add-variation-content">
                      <h3 className="variation-label">Variation Number</h3>
                      <input
                        label="Variation No"
                        value={variationNo || ""}
                        fullWidth
                        onChange={(e) => setVariationNo(e.target.value)}
                        margin="dense"
                        disabled={isEditMode} // Disable editing of variation number
                        className="variation-number"
                      />
                      <h3 className="variation-label">Variation Name</h3>
                      <input
                        label="Variation Name"
                        value={variationName || ""}
                        fullWidth
                        onChange={(e) => setVariationName(e.target.value)}
                        margin="dense"
                        className="variation-name"
                      />
                      <h3 className="variation-label">Variation Hours</h3>
                      <input
                        placeholder="Enter Variation Hours"
                        type="number"
                        value={variationHours || ""}
                        onChange={(e) =>
                          setVariationHours(Number(e.target.value))
                        }
                        fullWidth
                        margin="dense"
                        className="variation-hours"
                      />
                      {/* {isEditMode && (
                        <>
                          <h3 className="variation-label">Invoice Status</h3>
                          <select
                            className="variation-hours"
                            onChange={
                              (e) =>
                                updateClaimStatus(
                                  selectedVariation.id,
                                  e.target.value
                                ) // Update status
                            }
                            value={selectedVariation.invoice_status}
                          >
                            <option disabled>Select Invoice Status</option>
                            <option>Unclaimed</option>
                            <option>Claimed</option>
                          </select>
                        </>
                      )} */}
                    </div>

                    <div
                      className={
                        isEditMode
                          ? "btn-var-con"
                          : "add-variation-btn-con-second"
                      }
                    >
                      <button
                        onClick={handleSaveOrAddVariation}
                        style={{ marginLeft: "21px" }}
                        color="primary"
                        variant="contained"
                        className="add-edit-issue-btn"
                      >
                        {isEditMode ? "Save" : "Add"}
                      </button>
                      <button
                        onClick={() => setOpenVariationDialog(false)}
                        color="secondary"
                        className="cancel-btn"
                        style={{ marginRight: "140px" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}

                {/* Delete Confirmation Dialog */}
                {deleteDialogOpen && (
                  <div className="delete-var-issue-dial">
                    <div className="delete-var-header">
                      <h3 className="delete-variation">Delete Variation</h3>
                    </div>
                    <div style={{ backgroundColor: "white" }}>
                      <p
                        style={{
                          backgroundColor: "white",
                          marginTop: "0px",
                          marginLeft: "10px",
                        }}
                      >
                        Are you sure you want to delete this variation? This
                        action cannot be undone.
                      </p>
                      <input
                        type="checkbox"
                        checked={isCheckboxChecked}
                        id="tick"
                        onChange={() =>
                          setIsCheckboxChecked(!isCheckboxChecked)
                        }
                      />
                      <label
                        htmlFor="tick"
                        style={{ backgroundColor: "white", userSelect: "none" }}
                      >
                        I understand this action cannot be undone.
                      </label>
                    </div>
                    <div className="btn-var-del-con">
                      <button
                        onClick={handleDeleteConfirm}
                        color="secondary"
                        variant="contained"
                        disabled={!isCheckboxChecked}
                        style={{
                          backgroundColor: isCheckboxChecked ? "red" : "grey",
                        }}
                        className="add-btn"
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => {
                          setDeleteDialogOpen(false);
                          setIsCheckboxChecked(false);
                          setVariationToDelete([]);
                        }}
                        className="cancel-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
                {/*delete task dial*/}
              </Box>
            )}
            {/*Issue tab*/}
            {tabIndex === 2 && (
              <Box>
                <>
                  {issueToDelete.length > 0 && (
                    <button
                      onClick={() => setBulkAction((prev) => !prev)}
                      className="add-btn mass-update-var-issue"
                    >
                      Mass Update
                    </button>
                  )}
                  {/* {hasPermission("projects", "delete") &&
                    issueToDelete.length > 0 && (
                      <button
                        className="add-btn add-issue-btn delete-var-issue-btn"
                        onClick={() => setDeleteIssueDialogOpen(true)}
                        title="Delete Issue"
                      >
                        Delete
                      </button>
                    )} */}
                  {hasPermission("projects", "create") && (
                    <button
                      onClick={handleOpenIssueDialog}
                      style={{ textWrap: "nowrap", width: "auto" }}
                      className="add-btn add-issue-btn"
                      title="New Issue"
                    >
                      New Issue
                    </button>
                  )}
                </>

                <div
                  className={
                    isNavBarOpen
                      ? "variation-table-container"
                      : "variation-table-container-close"
                  }
                >
                  {issues.length === 0 && (
                    <div className="phase-image-container">
                      <img
                        className="phase-image-issues"
                        src="/AppLayout/Issue.png"
                      />
                      <p className="phase-message-issues">
                        No issue available for this project
                      </p>
                    </div>
                  )}

                  {issues.length !== 0 && (
                    <div
                      className={
                        isNavBarOpen
                          ? "variation-table-container"
                          : "variation-table-container-close"
                      }
                    >
                      <table className="variation-table">
                        <thead>
                          <tr>
                            {hasPermission("projects", "delete") && (
                              <th style={{ width: "2px" }}>
                                <FaChevronDown />
                              </th>
                            )}

                            <th>Issue Number</th>
                            <th>Issue Name</th>
                            <th>Issue Type</th>
                            <th>Raised By</th>
                            <th>Error By</th>
                            <th>Attended By</th>
                            {/* <th style={{ width: "10px" }}>Claim Status</th>
                            {!allIssuesUnclaimed && (
                              <>
                                <th style={{ width: "200px" }}>
                                  Invoice Number
                                </th>
                                <th>Invoice Date</th>
                              </>
                            )} */}
                          </tr>
                        </thead>
                        <tbody>
                          {issues.map((issue, index) => (
                            <tr key={index}>
                              {hasPermission("projects", "delete") && (
                                <td>
                                  <input
                                    type="checkbox"
                                    name={issue.name}
                                    checked={issueToDelete.includes(issue.id)} // Make sure this works properly
                                    onChange={(e) =>
                                      handleDeleteIssue(e, issue.id)
                                    }
                                  />
                                </td>
                              )}
                              <td>
                                {" "}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span style={{ marginTop: "6px" }}>
                                    {issue.issue_number}
                                  </span>
                                  {hasPermission("projects", "update") && (
                                    <button
                                      style={{
                                        width: "50px",
                                        border: "none",
                                        paddingRight: "50px",
                                        color: "#0000ff",
                                        backgroundColor: "transparent",
                                      }}
                                      onClick={() =>
                                        handleOpenEditIssueDialog(issue)
                                      }
                                      className="edit-icon-wrapper"
                                      title="Edit Issue"
                                    >
                                      <EditIcon />
                                    </button>
                                  )}{" "}
                                </div>
                              </td>{" "}
                              <td>{issue.name}</td>
                              <td
                                style={{ width: "10px", textAlign: "center" }}
                              >
                                {issue.type.charAt(0).toUpperCase() +
                                  issue.type.slice(1)}
                              </td>
                              <td>{issue.raisedby_name}</td>
                              <td>{issue.errorby_name}</td>
                              <td>{issue.attendedby_name}</td>
                              {/* <td
                                style={{
                                  backgroundColor:
                                    issue.invoice_status === "Claimed"
                                      ? "#a3d6a7"
                                      : "#d2b48c",
                                  color: "white",
                                  width: "140px",
                                  textAlign: "center",
                                  fontWeight: "550",
                                }}
                              >
                                <select
                                  style={{
                                    border: "none",
                                    backgroundColor:
                                      issue.invoice_status === "Claimed"
                                        ? "#a3d6a7"
                                        : "#d2b48c",
                                    color: "white",
                                    paddingLeft: "38px",
                                    paddingRight: "28px",
                                    fontWeight: "550",
                                  }}
                                  onChange={(e) =>
                                    updateIssueClaimStatus(
                                      issue.id,
                                      e.target.value
                                    )
                                  }
                                  value={issue.invoice_status}
                                >
                                  <option
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                      fontWeight: "550",
                                    }}
                                    value="Unclaimed"
                                  >
                                    Unclaimed
                                  </option>
                                  <option
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                      fontWeight: "550",
                                    }}
                                    value="Claimed"
                                  >
                                    Claimed
                                  </option>
                                </select>
                              </td>
                              {!allIssuesUnclaimed && (
                                <>
                                  <td>
                                    <input
                                      type="text"
                                      value={issue.invoice_no}
                                      className="invoice-element"
                                      placeholder="Enter Invoice Number"
                                    />
                                  </td>
                                  <td>
                                    <DatePicker
                                      selected={
                                        issue.invoice_date &&
                                        isValid(new Date(issue.invoice_date))
                                          ? new Date(issue.invoice_date)
                                          : null
                                      }
                                      disabled={
                                        issue.invoice_status === "Unclaimed"
                                      }
                                      placeholderText="Select Date"
                                      className="invoice-input"
                                      dateFormat="dd-MM-yyyy" // Display format for the picker
                                      minDate={new Date(0)} // Minimum date
                                      maxDate={new Date()} // Maximum date
                                    />
                                  </td>
                                </>
                              )} */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="issue-summary">
                        <p className="issue-para">
                          Total Number of Issues: {issues.length}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {bulkAction && (
                  <div className="action-box-variation" ref={issueRef}>
                    {hasPermission("projects", "delete") && (
                      <button
                        className="add-btn"
                        onClick={() => setDeleteIssueDialogOpen(true)}
                        title="Delete Issue"
                        style={{ backgroundColor: "red" }}
                      >
                        Delete
                      </button>
                    )}
                    {hasPermission("projects", "update") && (
                      <button
                        onClick={() => setClaimStatusIssue((prev) => !prev)}
                        className="add-btn"
                        style={{ textWrap: "nowrap" }}
                      >
                        Claim Status
                      </button>
                    )}
                    {claimStatusIssue &&
                      hasPermission("projects", "update") && (
                        <select
                          onChange={(e) =>
                            updateBulkIssueClaimStatus(
                              issueToDelete,
                              e.target.value
                            )
                          }
                          className="bulk-select"
                        >
                          <option>Select Claim Status</option>
                          <option>Claimed</option>
                          <option>Unclaimed</option>
                        </select>
                      )}
                  </div>
                )}
                {/* Add/Edit Issue Dialog */}
                {openIssueDialog && (
                  <div className="add-variation-dial">
                    <div className="dial-v-header">
                      <h3 className="add-edit-variation">
                        {isIssueEditMode ? "Edit Issue" : "Add Issue"}
                      </h3>
                    </div>
                    <div className="add-variation-content">
                      <h3 className="issue-label">Issue Number</h3>
                      <input
                        label="Issue No"
                        className="issue-number"
                        value={issueNo || ""}
                        fullWidth
                        onChange={(e) => setIssueNo(e.target.value)}
                        margin="dense"
                        disabled={isIssueEditMode} // Disable editing of issue number
                      />
                      <h3 className="issue-label">Issue Name</h3>
                      <input
                        label="Issue Name"
                        className="issue-name"
                        value={issueName || "Not Available"}
                        fullWidth
                        onChange={(e) => setIssueName(e.target.value)}
                        margin="dense"
                      />
                      {/* <h3 className="issue-label">Issue Hours</h3>
                      <input
                        label="Issue Hours"
                        className="issue-hour"
                        placeholder="Enter Issue Hours"
                        type="number"
                        value={issueHours || ""}
                        onChange={(e) => {
                          const newValue = Number(e.target.value);
                          setIssueHours(newValue);
                        }}
                        fullWidth
                        margin="dense"
                      /> */}
                      <h3 className="issue-label">Issue Type</h3>
                      <select
                        className="variation-hours"
                        value={issueType}
                        onChange={(e) => setIssueType(e.target.value)}
                      >
                        <option value="">Select Issue Type</option>
                        <option value="Internal">Internal</option>
                        <option value="External">External</option>
                      </select>
                      <h3 className="issue-label">Raised By</h3>
                      {issueType === "Internal" ? (
                        <select
                          className="variation-hours"
                          value={issueRaisedBy}
                          onChange={(e) => setIssueRaisedBy(e.target.value)}
                        >
                          <option>Select User</option>
                          {users
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((user) => (
                              <option key={user.id} value={user.id}>
                                {user.name}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <select
                          className="variation-hours"
                          value={issueRaisedBy}
                          onChange={(e) => setIssueRaisedBy(e.target.value)}
                        >
                          <option>Select Contact</option>
                          {/* Check if customer is an object */}
                          {Array.isArray(customer.contacts) &&
                            customer.contacts.map((contact) => (
                              <option
                                key={contact.contact_id}
                                value={contact.contact_id}
                              >
                                {contact.contact_name}
                              </option>
                            ))}
                        </select>
                      )}
                      <h3 className="issue-label">Error By</h3>
                      <select
                        className="variation-hours"
                        value={issueErrorBy}
                        onChange={(e) => setIssueErrorBy(e.target.value)}
                      >
                        <option>Select User </option>
                        {users
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((user) => (
                            <option key={user.id} value={user.id}>
                              {user.name}
                            </option>
                          ))}
                      </select>
                      <h3 className="issue-label">Attended By</h3>
                      <select
                        className="variation-hours"
                        value={issueAttendedBy}
                        onChange={(e) => setIssueAttendedBy(e.target.value)}
                      >
                        <option>Select User </option>
                        {users
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((user) => (
                            <option key={user.id} value={user.id}>
                              {user.name}
                            </option>
                          ))}
                      </select>
                      {isIssueEditMode && (
                        <>
                          <h3 className="variation-label">Invoice Status</h3>
                          <select
                            className="variation-hours"
                            onChange={(e) =>
                              updateIssueClaimStatus(
                                selectedIssue.id,
                                e.target.value
                              )
                            }
                            value={selectedIssue.invoice_status}
                          >
                            <option disabled>Select Invoice Status</option>
                            <option>Unclaimed</option>
                            <option>Claimed</option>
                          </select>
                        </>
                      )}
                    </div>

                    <div
                      className={
                        isIssueEditMode
                          ? "btn-issue-con"
                          : "add-issue-btn-con-second"
                      }
                    >
                      <button
                        onClick={
                          isIssueEditMode ? handleSaveIssue : handleAddIssue
                        }
                        color="primary"
                        variant="contained"
                        className="add-edit-issue-btn"
                      >
                        {isIssueEditMode ? "Save" : "Add"}
                      </button>
                      <button
                        onClick={() => setOpenIssueDialog(false)}
                        color="secondary"
                        className="cancel-btn"
                        style={{ marginRight: "140px" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}

                {/* Delete Confirmation Dialog */}
                {deleteIssueDialogOpen && (
                  <div className="delete-issue-dial">
                    <div className="delete-var-header">
                      <h3 className="delete-variation">Delete Issue</h3>
                    </div>
                    <div style={{ backgroundColor: "white" }}>
                      <p
                        style={{
                          backgroundColor: "white",
                          marginTop: "0px",
                          marginLeft: "10px",
                        }}
                      >
                        Are you sure you want to delete this issue? This action
                        cannot be undone.
                      </p>
                      <input
                        style={{ backgroundColor: "white" }}
                        type="checkbox"
                        id="tick-issue"
                        checked={isIssueCheckboxChecked}
                        onChange={() =>
                          setIsIssueCheckboxChecked(!isIssueCheckboxChecked)
                        }
                      />
                      <label
                        htmlFor="tick-issue"
                        style={{ backgroundColor: "white", userSelect: "none" }}
                      >
                        I understand this action cannot be undone.
                      </label>
                    </div>
                    <div className="btn-issue-del-con">
                      <button
                        onClick={handleDeleteIssueConfirm}
                        color="primary"
                        variant="contained"
                        disabled={!isIssueCheckboxChecked}
                        style={{
                          backgroundColor: isIssueCheckboxChecked
                            ? "red"
                            : "grey",
                        }}
                        className="add-btn"
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => {
                          setDeleteIssueDialogOpen(false);
                          setIsIssueCheckboxChecked(false);
                        }}
                        color="secondary"
                        style={{ marginRight: "140px" }}
                        className="cancel-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </Box>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PhaseDetails;
