import React, { useState, useEffect } from "react";
import { useRolePermissions } from "./RoleContext.js";
import { CircularProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import api from "../../api/axiosConfig.js";
import { Helmet } from "react-helmet";

const TaskTemplate = () => {
  const [tasks, setTasks] = useState([]);
  const [taskTemplate, setTaskTemplate] = useState([]);
  const [activeTemplateId, setActiveTemplateId] = useState(null);
  const [addTaskDial, setAddTaskDial] = useState(false);
  const [addTemplateDial, setAddTemplateDial] = useState(false);
  const [templateEditable, setTemplateEditable] = useState(false);
  const [taskTemplateEditable, setTaskTemplateEditable] = useState(false);
  const { hasPermission } = useRolePermissions();
  const [newTask, setNewTask] = useState({ name: "" });
  const [newTaskTemplate, setNewTaskTemplate] = useState({ name: "" });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [selectedTaskTemplateId, setSelectedTaskTemplateId] = useState(null);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [selectedTaskTemplateIds, setSelectedTaskTemplateIds] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [taskTemplateDeleteDialogOpen, setTaskTemplateDeleteDialogOpen] =
    useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get("/api/tasktemplates");
        setTasks(res.data);
      } catch (error) {
        setError(error);
        console.error("Error fetching task templates:", error.message || error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleTaskTemplate = async (taskTemplateId) => {
    try {
      const templateRes = await api.get(`/api/tasks/${taskTemplateId}`);
      setTaskTemplate(templateRes.data);
    } catch (error) {
      console.error("Error fetching tasks:", error.message || error);
    }
    setActiveTemplateId(taskTemplateId);
  };

  const handleAddOrEditTask = async () => {
    try {
      if (templateEditable && selectedTaskId) {
        // Editing an existing task
        const response = await api.put(
          `/api/tasks/${activeTemplateId}/${selectedTaskId}`,
          { name: newTask.name }
        );
        // After updating, refresh the task list for the active template
        await handleTaskTemplate(activeTemplateId);
      } else {
        // Adding a new task
        const response = await api.post(`/api/tasks/${activeTemplateId}`, {
          name: newTask.name,
        });
        // Add the new task to the local state using the response data
        setTaskTemplate((prevTask) => [...prevTask, response.data]);

        // Refresh the task list for the active template
        await handleTaskTemplate(activeTemplateId);
      }

      // Reset new task name and close dialog
      setNewTask({ name: "" });
      setAddTaskDial(false);
      setTemplateEditable(false);
    } catch (error) {
      console.error("Error adding or editing task:", error.message || error);
    }
  };

  const handleCheckboxChange = (taskId) => {
    setSelectedTaskIds((prevSelectedTaskIds) => {
      if (prevSelectedTaskIds.includes(taskId)) {
        return prevSelectedTaskIds.filter((id) => id !== taskId); // Remove from selected
      } else {
        return [...prevSelectedTaskIds, taskId]; // Add to selected
      }
    });
  };

  const handleDeleteTask = async () => {
    try {
      for (const taskId of selectedTaskIds) {
        // Find the task by ID to get its name
        const task = taskTemplate.find((t) => t.id === taskId);
        const taskName = task ? task.name : `Task ID ${taskId}`;

        try {
          // Send a request to delete the task
          await api.delete(`/api/tasks/${taskId}`);
          // Remove the task from local state after successful deletion
          setTaskTemplate((prevTasks) =>
            prevTasks.filter((task) => task.id !== taskId)
          );
        } catch (error) {
          // Handle individual deletion errors
          if (error.response && error.response.status === 400) {
            const associatedCount = error.response.data.associatedCount || 0;
            const phaseText = associatedCount === 1 ? "phase" : "phases"; // Determine singular or plural
            console.error(
              `Task "${taskName}" cannot be deleted because it is associated with ${associatedCount} ${phaseText}.`
            );
            alert(
              `Task "${taskName}" cannot be deleted because it is associated with ${associatedCount} ${phaseText}.`
            );
          } else {
            console.error(
              `An error occurred while deleting task "${taskName}":`,
              error.message || error
            );
            alert(
              `An unexpected error occurred while deleting task "${taskName}".`
            );
          }
        }
      }
      // Clear the selected task IDs after attempting deletions
      setSelectedTaskIds([]);
      setDeleteDialogOpen(false);
      setIsCheckboxChecked(false);
    } catch (error) {
      console.error(
        "Unexpected error during task deletion:",
        error.message || error
      );
      alert("An unexpected error occurred while deleting tasks.");
    }
  };

  const handleAddOrEditTaskTemplate = async () => {
    try {
      if (taskTemplateEditable && selectedTaskTemplateId) {
        // Editing an existing task template
        const response = await api.put(
          `/api/tasktemplates/${selectedTaskTemplateId}`,
          { name: newTaskTemplate.name }
        );
        setTasks((prevTasks) =>
          prevTasks.map((template) =>
            template.id === selectedTaskTemplateId
              ? { ...template, name: newTaskTemplate.name }
              : template
          )
        );
      } else {
        // Adding a new task template
        const response = await api.post("/api/tasktemplates", newTaskTemplate);
        setTasks((prevTasks) => [
          ...prevTasks,
          { id: response.data.templateId, name: newTaskTemplate.name },
        ]);
      }

      setNewTaskTemplate({ name: "" });
      setAddTemplateDial(false);
      setTaskTemplateEditable(false);
    } catch (error) {
      console.error(
        "Error adding or editing task template:",
        error.message || error
      );
    }
  };
  const handleCheckboxChangeForTemplate = (templateId) => {
    setSelectedTaskTemplateIds((prevSelectedTaskTemplateIds) => {
      if (prevSelectedTaskTemplateIds.includes(templateId)) {
        return prevSelectedTaskTemplateIds.filter((id) => id !== templateId); // Remove from selected
      } else {
        return [...prevSelectedTaskTemplateIds, templateId]; // Add to selected
      }
    });
  };
  //delete task template
  const handleDeleteTaskTemplate = async () => {
    try {
      for (const templateId of selectedTaskTemplateIds) {
        // Find the task template by ID to get its name
        const taskTemplate = tasks.find(
          (template) => template.id === templateId
        );
        const taskTemplateName = taskTemplate
          ? taskTemplate.name
          : `Task ID ${templateId}`;

        try {
          // Send a request to delete the task template
          await api.delete(`/api/tasktemplates/${templateId}`);
          // Remove the task from local state after successful deletion
          setTasks((prevTasks) =>
            prevTasks.filter((template) => template.id !== templateId)
          );
        } catch (error) {
          // Handle individual deletion errors
          if (error.response && error.response.status === 400) {
            const associatedCount = error.response.data.associatedCount || 0;
            const phaseText = associatedCount === 1 ? "phase" : "phases"; // Determine singular or plural
            console.error(
              `Task template "${taskTemplateName}" cannot be deleted because it is associated with ${associatedCount} ${phaseText}.`
            );
            alert(
              `Task template "${taskTemplateName}" cannot be deleted because it is associated with ${associatedCount} ${phaseText}.`
            );
          } else {
            console.error(
              `An error occurred while deleting task template "${taskTemplateName}":`,
              error.message || error
            );
            alert(
              `An unexpected error occurred while deleting task template "${taskTemplateName}".`
            );
          }
        }
      }
      // Clear the selected task IDs after attempting deletions
      setSelectedTaskTemplateIds([]);
      setIsCheckboxChecked(false);
      setTaskTemplateDeleteDialogOpen(false);
    } catch (error) {
      console.error(
        "Unexpected error during task template deletion:",
        error.message || error
      );
      alert("An unexpected error occurred while deleting task templates.");
    }
  };

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  }
  // if (error) {
  //   return <CircularProgress />;
  // }

  return (
    <div className="group-main-container" style={{ height: "848px" }}>
      <Helmet>
        <title>Msuite - Projects</title>
      </Helmet>
      <div className="task-btn-containers">
        {hasPermission("projects", "delete") &&
          selectedTaskTemplateIds.length > 0 && (
            <button
              className="delete-template"
              onClick={() => setTaskTemplateDeleteDialogOpen(true)}
              title="Delete Task Template"
            >
              Delete Task Template
            </button>
          )}
        {hasPermission("projects", "create") && (
          <button
            className={
              selectedTaskTemplateIds.length > 0
                ? "add-template"
                : "add-templates"
            }
            onClick={() => setAddTemplateDial(true)}
            title="New Task Template"
          >
            Add Task Template
          </button>
        )}
      </div>
      <div className="task-templates-container">
        <div className="task-container">
          <ul>
            {tasks
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((template) => (
                <li
                  key={template.id}
                  style={{
                    cursor: "pointer",
                    color:
                      activeTemplateId === template.id ? "#6200ee" : "black",
                    fontWeight:
                      activeTemplateId === template.id ? "bold" : "normal",
                  }}
                  className="template-names"
                >
                  <span>
                    {hasPermission("projects", "delete") && (
                      <input
                        type="checkbox"
                        checked={selectedTaskTemplateIds.includes(template.id)}
                        onChange={() =>
                          handleCheckboxChangeForTemplate(template.id)
                        }
                        className="checkbox-task"
                      />
                    )}
                  </span>
                  <span onClick={() => handleTaskTemplate(template.id)}>
                    {template.name}
                  </span>
                  <span className="edit-icon-wrapper">
                    <EditIcon
                      onClick={() => {
                        setTaskTemplateEditable(true);
                        setNewTaskTemplate({ name: template.name });
                        setSelectedTaskTemplateId(template.id);
                        setAddTemplateDial(true);
                      }}
                      style={{
                        backgroundColor: "transparent",
                        color: "blue",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </li>
              ))}
          </ul>
        </div>
        <div className="templates-container">
          {hasPermission("projects", "create") && activeTemplateId && (
            <button
              className="new-task-btn"
              onClick={() => setAddTaskDial(true)}
              title="New Task"
            >
              New Task
            </button>
          )}
          {hasPermission("projects", "delete") &&
            selectedTaskIds.length > 0 && (
              <button
                className="new-task-btn delete-task"
                onClick={() => setDeleteDialogOpen(true)}
                title="Delete Task"
              >
                Delete Task
              </button>
            )}

          {activeTemplateId && taskTemplate.length === 0 ? (
            <p>No Tasks available for this template</p>
          ) : (
            <ul className="template-list">
              {taskTemplate.map((task) => (
                <li key={task.id} className="template-names">
                  <span>
                    {hasPermission("projects", "delete") && (
                      <input
                        type="checkbox"
                        checked={selectedTaskIds.includes(task.id)}
                        onChange={() => handleCheckboxChange(task.id)}
                        className="checkbox-task"
                        id={task.id}
                      />
                    )}
                  </span>
                  <label htmlFor={task.id} className="template-names ">
                    {task.name}
                  </label>
                  <span className="edit-icon-wrapper">
                    <EditIcon
                      onClick={() => {
                        setTemplateEditable(true);
                        setNewTask({ name: task.name });
                        setSelectedTaskId(task.id); // Set the task ID to be edited
                        setAddTaskDial(true); // Open the dialog
                      }}
                      style={{
                        backgroundColor: "transparent",
                        color: "blue",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {(addTaskDial || templateEditable) && (
        <div className="add-layout-new-user-container add-task-dial">
          <div className="edit-task-template-dial">
            <div className="edit-task-header">
              <h3 className="add-new-user-heading">
                {templateEditable ? "Edit Task" : "Add Task"}
              </h3>
            </div>
            <h3 className="label-header-element">Task Name</h3>
            <input
              className="user-element"
              placeholder="Enter Task Name"
              type="text"
              value={newTask.name}
              onChange={(e) => setNewTask({ name: e.target.value })}
            />
            <div className="task-btn-container">
              <button className="add-btn" onClick={handleAddOrEditTask}>
                {templateEditable ? "Save" : "Add"}
              </button>
              <button
                className="cancel-btn"
                onClick={() => {
                  setAddTaskDial(false);
                  setTemplateEditable(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {(addTemplateDial || taskTemplateEditable) && (
        <div className="add-layout-new-user-container add-task-dial">
          <div className="add-task-template-dial">
            <div className="task-add-header">
              <h3 className="add-new-user-heading">
                {taskTemplateEditable
                  ? "Edit Task Template"
                  : "Add Task Template"}
              </h3>
            </div>
            <h3 className="label-header-element">Task Template Name</h3>
            <input
              className="user-element"
              type="text"
              placeholder="Enter Task Template Name"
              value={newTaskTemplate.name}
              onChange={(e) => setNewTaskTemplate({ name: e.target.value })}
            />
            <div className="task-btn-container">
              <button className="add-btn" onClick={handleAddOrEditTaskTemplate}>
                {taskTemplateEditable ? "Save" : "Add"}
              </button>
              <button
                className="cancel-btn"
                onClick={() => {
                  setAddTemplateDial(false);
                  setTaskTemplateEditable(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {(deleteDialogOpen || taskTemplateDeleteDialogOpen) && (
        <div className="delete-phase-dial">
          {" "}
          <div className="delete-header">
            <h3 style={{ backgroundColor: "transparent", marginLeft: "15px" }}>
              {deleteDialogOpen ? "Delete Task" : "Delete Task Template"}
            </h3>
          </div>
          <div style={{ backgroundColor: "white" }}>
            <p style={{ backgroundColor: "white" }}>
              Are you sure you want to delete this{" "}
              {deleteDialogOpen ? "Task" : "Task Template"} This action cannot
              be undone.
            </p>
            <input
              type="checkbox"
              id="delete-phase"
              checked={isCheckboxChecked}
              onChange={() => setIsCheckboxChecked((prev) => !prev)}
            />
            <label htmlFor="delete-phase" style={{ backgroundColor: "white" }}>
              I understand this action cannot be undone.
            </label>
          </div>
          <div className="delete-dial-btn-con">
            <button
              onClick={() => {
                deleteDialogOpen
                  ? handleDeleteTask(true)
                  : handleDeleteTaskTemplate(true);
              }}
              color="secondary"
              variant="contained"
              disabled={!isCheckboxChecked}
              className="add-btn"
              style={{ backgroundColor: isCheckboxChecked ? "red" : "gray" }}
            >
              Delete
            </button>

            <button
              onClick={() => {
                setDeleteDialogOpen(false);
                setTaskTemplateDeleteDialogOpen(false);
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskTemplate;
