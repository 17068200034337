import React, { useMemo } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const TimesheetPieChart = ({ projectName, timesheetData }) => {
  // Calculate billable and non-billable hours
  const billableHours = useMemo(() => {
    let billable = 0;
    let nonBillable = 0;

    timesheetData.forEach((entry) => {
      const { billable: isBillable, duration } = entry;
      const [hours, minutes] = duration.split(":").map(Number);
      const totalHours = hours + minutes / 60;

      if (isBillable === 1) {
        billable += totalHours;
      } else {
        nonBillable += totalHours;
      }
    });

    return { billable, nonBillable };
  }, [timesheetData]);

  // Prepare data for the Pie chart
  const data = useMemo(() => {
    return {
      labels: ["Billable", "Non-Billable"],
      datasets: [
        {
          label: projectName,
          data: [billableHours.billable, billableHours.nonBillable],
          backgroundColor: ["#4caf50", "#f44336"], // Green for billable, red for non-billable
          hoverBackgroundColor: ["#388e3c", "#d32f2f"], // Darker shades for hover effect
          hoverOffset: 10, // Increases slice size on hover
        },
      ],
    };
  }, [billableHours, projectName]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        enabled: true,
      },
    },
    hover: {
      mode: "nearest",
      animationDuration: 200,
      shadowBlur: 10,
      shadowColor: "rgba(0, 0, 0, 0.3)",
    },
    animation: {
      duration: 500,
      easing: "easeOutCirc",
    },
  };

  return (
    <div>
      <h3>Billable vs Non-Billable - {projectName}</h3>
      {data &&
      data.datasets &&
      data.datasets.length > 0 &&
      data.datasets[0].data.some((value) => value > 0) ? (
        <Pie
          data={data}
          options={options}
          className="piechart"
          style={{ marginTop: "62px" }}
        />
      ) : (
        <div className="duration-image-container dashboard-image-extra">
          <img
            className="billable-image"
            src="/AppLayout/nonbillable-Billable.png"
          />
          <p>Booked hours not available for this project</p>
        </div>
      )}
    </div>
  );
};

export default TimesheetPieChart;
