import React from "react";
import { Bar } from "react-chartjs-2";
import "../../styles/Home.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ProjectManagerBarChart = ({ projects }) => {
  // Step 1: Aggregate the project counts by project manager
  const managerCounts = projects.reduce((acc, project) => {
    const manager = project.project_manager_name;
    acc[manager] = (acc[manager] || 0) + 1;
    return acc;
  }, {});

  // Step 2: Prepare data for the Bar chart
  const chartData = {
    labels: Object.keys(managerCounts), // Project manager names
    datasets: [
      {
        label: "Number of Projects",
        data: Object.values(managerCounts), // Count of projects per manager
        backgroundColor: "rgba(54, 162, 235, 0.6)", // Bar color
        borderColor: "rgba(54, 162, 235, 1)", // Border color
        borderWidth: 1,
      },
    ],
  };

  // Step 3: Options to customize the chart appearance (optional)
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Projects by Project Manager",
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Project Managers",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Projects",
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="project-bar-status">
      <h2 className="pie-header">Projects by Project Manager</h2>
      <Bar
        data={chartData}
        className="manager"
        options={options}
        style={{ width: "100%" }}
      />
      {/* options={options} */}
    </div>
  );
};

export default ProjectManagerBarChart;
