import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import "../../styles/projects/ProjectDetails.css";
import { ProjectContext } from "../../App";
import DatePicker from "react-datepicker";
import { isValid } from "date-fns";
import { useRolePermissions } from "../Settings/RoleContext";
import api from "../../api/axiosConfig";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgress } from "@mui/material";
import { FaChevronDown } from "react-icons/fa";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { Helmet } from "react-helmet";

const fetchData = async (url, setData, setError) => {
  try {
    const response = await api.get(url); // Axios automatically adds the baseURL and headers
    setData(response.data); // Axios automatically parses JSON
  } catch (error) {
    console.error("Error fetching data:", error);
    setError(error); // Handle error
  }
};

const addPhase = async (projectId, phase, setError) => {
  try {
    const response = await api.post(`/api/project/${projectId}/phases`, phase);
    // window.location.reload();
    return response.data;
  } catch (error) {
    if (error.response) {
      // Server responded with a specific error
      console.error("Server error:", error.response.data);
      setError(error.response.data.message || "Failed to add phase.");
    } else if (error.request) {
      // Request was made, but no response received
      console.error("No response received:", error.request);
      setError("Server did not respond. Please try again later.");
    } else {
      // Other errors (e.g., network issues)
      console.error("Unexpected error:", error.message);
      setError(error.message || "An unexpected error occurred.");
    }
  }
};

const deletePhase = async (phaseIds, setError) => {
  try {
    const url = `/api/phases`; // No phaseId in the URL, we're using the request body
    await api.delete(url, { data: { phaseIds } }); // Send phaseIds in the request body
  } catch (error) {
    if (error.response) {
      // If the server responded with an error
      const serverError = error.response.data;
      console.error("Server error:", serverError);
      setError(
        serverError.message ||
          "An error occurred while deleting phases. Please try again."
      );
      alert(
        serverError.message ||
          "An error occurred while deleting phases. Please try again."
      );
      window.location.reload();
    } else {
      // Generic error handling
      console.error("Unexpected error:", error);
      setError("Unexpected error occurred. Please try again.");
    }
  }
};

const ProjectDetails = () => {
  const { hasPermission } = useRolePermissions();
  const { projectId } = useParams();
  const [project, setProject] = useState({
    id: null,
    name: "",
    projectno: "",
    customer_name: "",
    phases: [],
  });
  const [loading, setLoading] = useState(true);
  const profileRef = useRef(null);
  const { dialBoxopen, isNavBarOpen } = useContext(ProjectContext);
  const [error, setError] = useState(null);
  const [claimPercent, setClaimPercent] = useState(0);
  const [phaseDialogOpen, setPhaseDialogOpen] = useState(false);
  const [phaseEditDialogOpen, setPhaseEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedPhaseId, setSelectedPhaseId] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [totalPlannedHours, setTotalPlannedHours] = useState("");
  const [editingPhase, setEditingPhase] = useState(null); // Holds the phase being edited
  const [bulkAction, setBulkAction] = useState(false);
  const [claimStatus, setClaimStatus] = useState("");
  const [formValues, setFormValues] = useState({
    phaseno: "",
    title: "",
    plannedhours: "",
  });
  const [newPhase, setNewPhase] = useState({
    title: "",
    plannedhours: 0,
    phaseno: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUserssPerPage] = useState(13);
  const [phaseActualHours, setPhaseActualHours] = useState({});

  const updateProjectsPerPage = () => {
    if (window.innerWidth < 1919) {
      setUserssPerPage(9); // Small screens (e.g., mobile)
    } else if (window.innerWidth >= 1920) {
      setUserssPerPage(13); // Medium screens (e.g., tablet)
    } else {
      setUserssPerPage(10); // Large screens (e.g., desktop)
    }
  };
  useEffect(() => {
    // Set initial projects per page
    updateProjectsPerPage();

    // Update projects per page when the window is resized
    window.addEventListener("resize", updateProjectsPerPage);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", updateProjectsPerPage);
  }, []);
  useEffect(() => {
    console.log("project data", project);
  }, [project]);
  // useEffect that calculates claimPercent
  useEffect(() => {
    // Fetch data and calculate claim percentage
    fetchData(
      `/api/project/${projectId}/phasesplannedhours`,
      (data) => {
        const sanitizedPhases = Array.isArray(data.phases)
          ? data.phases
              .filter(
                (phase) =>
                  phase.phase_id !== null &&
                  phase.title !== null &&
                  phase.task_count !== null
              )
              .map((phase) => {
                return phase;
              })
          : [];

        // Calculate total planned hours in decimal format
        const totalDecimalHours = sanitizedPhases.reduce((total, phase) => {
          // Convert "planned_hours" to hours in decimal format (HH:mm -> hours)
          const [hours, minutes] = phase.planned_hours.split(":").map(Number);
          const decimalHours = hours + minutes / 60;
          return total + decimalHours;
        }, 0);

        // Convert totalDecimalHours back to hh:mm format
        const totalHours = Math.floor(totalDecimalHours); // Get hours
        const totalMinutes = Math.round((totalDecimalHours - totalHours) * 60); // Get minutes

        // Format total planned hours in hh:mm format
        const formattedTotalPlannedHours = `${totalHours
          .toString()
          .padStart(2, "0")}:${totalMinutes.toString().padStart(2, "0")}`;

        // Store formatted total planned hours in the state
        setTotalPlannedHours(formattedTotalPlannedHours);

        // Set the project data with sanitized phases
        setProject({ ...data, phases: sanitizedPhases });
      },
      setError
    ).finally(() => setLoading(false));
  }, [projectId]);

  const handleOpenPhaseDialog = () => {
    const defaultPhaseNo = `${project.project_number}-phase-${
      project.phases.length + 1
    }`;
    setNewPhase({
      project_id: project.id,
      title: defaultPhaseNo,
      plannedhours: 0,
      phaseno: defaultPhaseNo,
    });
    setPhaseDialogOpen(true);
    document.body.classList.add("no-scroll");
  };

  const handleClosePhaseDialog = () => {
    setPhaseDialogOpen(false);
    document.body.classList.remove("no-scroll");
  };

  const handleAddPhase = async () => {
    const newPhaseData = await addPhase(projectId, newPhase, setError);
    console.log("new phase data:", newPhaseData); // Log newPhaseData to ensure it's correctly returned

    if (newPhaseData) {
      setProject((prev) => {
        // Log the previous phases to ensure it's an array
        console.log("previous phases:", prev.phases);

        const updatedPhases = [
          ...prev.phases,
          {
            phaseno: newPhaseData.phaseno,
            title: newPhaseData.title,
            plannedhours: newPhaseData.plannedhours,
            phase_id: newPhaseData.id,
          },
        ];
        console.log("updated phases after state update:", updatedPhases);
        return {
          ...prev,
          phases: updatedPhases,
        };
      });
    }

    document.body.classList.add("no-scroll");
    setPhaseDialogOpen(false);
  };

  const handleEditClick = (phase) => {
    setEditingPhase(phase.phase_id);

    setFormValues({
      phaseno: phase.phaseno,
      title: phase.title,
      plannedhours: phase.plannedhours,
    });
    setPhaseEditDialogOpen(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    const { phaseno, title, plannedhours } = formValues;
    const phaseId = editingPhase;
    try {
      const response = await api.put(`/api/phase/${phaseId}`, {
        phaseno,
        title,
        plannedhours,
      });
      // Axios responses don't have .ok, they throw errors automatically
      const updatedPhase = response.data;
      console.log("updated data", updatedPhase);
      const updatedPhases = project.phases.map((phase) =>
        phase.phase_id === phaseId
          ? {
              ...phase,
              phaseno: updatedPhase.phaseno,
              title: updatedPhase.title,
              plannedhours: updatedPhase.plannedhours,
              id: updatedPhase.phase_id,
            }
          : phase
      );
      setProject((prevProject) => ({
        ...prevProject,
        phases: updatedPhases,
      }));
      setPhaseEditDialogOpen(false);
    } catch (error) {
      // Axios errors are inside error.response
      let errorMessage = "Failed to update phase";
      if (error.response) {
        errorMessage = error.response.data.message || errorMessage;
      }
      console.error(errorMessage);
      console.log(errorMessage);
    }
  };

  const handleDeletePhaseClick = (e, phaseId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add phaseId to the selectedPhaseId array
      setSelectedPhaseId((prev) => [...prev, phaseId]);
    } else {
      // Remove phaseId from the selectedPhaseId array
      setSelectedPhaseId((prev) => prev.filter((id) => id !== phaseId));
    }

    document.body.classList.add("no-scroll");
  };
  //delete phase
  const handleDeleteConfirm = async () => {
    if (isCheckboxChecked) {
      try {
        // Call the deletePhase function with the selected phase IDs
        await deletePhase(selectedPhaseId, setError);
        // Update the state after deletion
        setProject((prev) => {
          // Filter out the phases whose IDs are in the selectedPhaseId array
          const updatedPhases = prev.phases.filter(
            (phase) => !selectedPhaseId.includes(phase.phase_id) // Remove phases whose IDs are in selectedPhaseId
          );

          return {
            ...prev,
            phases: updatedPhases, // Update phases after deletion
          };
        });

        // Close the delete dialog and reset UI states
        setDeleteDialogOpen(false);
        setBulkAction(false);
        setClaimStatus(false);
        document.body.classList.remove("no-scroll");
        setIsCheckboxChecked(false); // Reset the checkbox state
        setSelectedPhaseId([]); // Clear the selected phases after deletion
      } catch (error) {
        console.error("Failed to delete phase:", error); // Log the error for debugging
        setError(error); // Display the error message to the user
      }
    } else {
      alert("Please check the checkbox to confirm deletion."); // Alert if no checkbox is checked
    }
  };

  //close dial and remove no-scroll
  const handleCloseDial = () => {
    setIsCheckboxChecked(false);
    setDeleteDialogOpen(false);
    setSelectedPhaseId([]);
    document.body.classList.remove("no-scroll");
  };

  const updateClaimStatus = async (phaseId, newValue, fieldType, userId) => {
    try {
      if (fieldType === "status" && newValue === "Unclaimed") {
        // Show a confirmation popup
        const confirmUnclaim = window.confirm(
          "Are you sure you want to change the status to Unclaimed?"
        );

        // If user clicks "No", return without making changes
        if (!confirmUnclaim) {
          return; // No changes, exit function
        }
      }
      let requestData = {};

      // Determine the type of update based on the `fieldType`
      if (fieldType === "status") {
        requestData = { status: newValue }; // Update only the status
      } else if (fieldType === "date") {
        requestData = { date: newValue ? newValue.toISOString() : null }; // Update only the date
      } else if (fieldType === "number") {
        requestData = { invoice_no: newValue };
      } else {
        console.error("Invalid field type for updateClaimStatus.");
        return;
      }

      // Send the request to the backend
      const response = await api.put(
        `/api/phase/${phaseId}/${userId}/approval`,
        requestData
      );

      if (response.status === 200) {
        // Update the local state if necessary
        fetchData(
          `/api/project/${projectId}/phasesplannedhours`,
          (data) => {
            const sanitizedPhases = Array.isArray(data.phases)
              ? data.phases
                  .filter(
                    (phase) =>
                      phase.phase_id !== null &&
                      phase.title !== null &&
                      phase.task_count !== null
                  )
                  // Perform any additional data transformation here
                  .map((phase) => phase)
              : [];
            setProject({ ...data, phases: sanitizedPhases });
          },
          setError
        ).finally(() => {
          setLoading(false);
          console.log("Loading complete");
        });
      } else {
        console.error(
          "Failed to update phase:",
          response.statusText || "No status text"
        );
      }
    } catch (error) {
      console.error("Error updating phase:", error);
    }
  };

  const updateBulkClaimStatus = async (phaseIds, newStatus) => {
    try {
      // If the new status is "Unclaimed", show confirmation
      if (newStatus === "Unclaimed") {
        const confirmUnclaim = window.confirm(
          "Are you sure you want to change the status of selected phases to Unclaimed?"
        );

        // If user clicks "No", stop the function
        if (!confirmUnclaim) {
          return; // Exit without making changes
        }
      }

      const response = await api.put("/api/phases/batch-update", {
        phaseIds,
        status: newStatus,
      });

      if (response.status === 200) {
        alert(response.data.message); // Show success message
        // Optionally, refresh data
        fetchData(
          `/api/project/${projectId}/phasesplannedhours`,
          (data) => {
            const sanitizedPhases = Array.isArray(data.phases)
              ? data.phases
                  .filter(
                    (phase) =>
                      phase.phase_id !== null &&
                      phase.title !== null &&
                      phase.task_count !== null
                  )
                  //actualhours calculation starts
                  .map((phase) => {
                    return phase;
                  })
              : //actualhours calculation ends
                [];
            setProject({ ...data, phases: sanitizedPhases });
          },
          setError
        );
        setBulkAction(false);
        setSelectedPhaseId([]);
        setClaimStatus(false);
      } else {
        alert(response.data.message); // Handle other cases (e.g., no rows updated)
      }
    } catch (error) {
      console.error("Failed to update bulk statuses", error);
      alert(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setBulkAction(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
  }, []);

  const totalPages = Math.ceil(project.phases.length / usersPerPage);

  // Get current projects to display
  const indexOfLastUsers = currentPage * usersPerPage;
  const indexOfFirstUsers = indexOfLastUsers - usersPerPage;
  // const currentUsers = customers.slice(indexOfFirstUsers, indexOfLastUsers);

  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };
  const handleFirstPage = () => {
    if (currentPage > 1) setCurrentPage(1);
  };
  const handleLastPage = () => {
    if (currentPage < totalPages) setCurrentPage(totalPages);
  };

  const allUnclaimed = project.phases.every(
    (phase) => phase.invoice_status === "Unclaimed"
  );

  if (loading)
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  if (error)
    return (
      <p color="error" className="error-message">
        Error: {error.message}
      </p>
    );

  return (
    <>
      <Helmet>
        <title>Msuite - Projects</title>
      </Helmet>
      {!dialBoxopen && (
        <div
          className={
            isNavBarOpen
              ? "project-phase-details"
              : "project-phase-details-close"
          }
        >
          <div style={{ marginLeft: "103px" }}>
            <div>
              {selectedPhaseId.length > 0 && (
                <button
                  onClick={() => setBulkAction((prev) => !prev)}
                  className="add-btn mass-update-phase"
                >
                  Mass Update
                </button>
              )}
              {hasPermission("projects", "update") && (
                <button
                  onClick={handleOpenPhaseDialog}
                  className={
                    selectedPhaseId.length > 0
                      ? "add-phase-btn"
                      : "add-phase-btn-close"
                  }
                  title="Add Phase"
                >
                  New Phase
                </button>
              )}
              {project.phases.length === 0 ? (
                <div className="phase-image-container">
                  <img className="phase-image" src="/AppLayout/Phase.png" />
                  <p className="phase-message">
                    No phases available for this project
                  </p>
                </div>
              ) : (
                <>
                  <div
                    className={
                      isNavBarOpen ? "table-container" : "table-container-close"
                    }
                  >
                    <table
                      className={
                        isNavBarOpen ? "phase-table" : "phase-table-close"
                      }
                    >
                      <thead>
                        <tr>
                          {hasPermission("projects", "delete") && (
                            <th style={{ width: "2px", textAlign: "center" }}>
                              <FaChevronDown />
                            </th>
                          )}
                          <th
                            style={{
                              color: "#616161",
                              fontWeight: "400",
                              color: "black",
                            }}
                          >
                            Phase No
                          </th>
                          <th
                            style={{
                              color: "#616161",
                              fontWeight: "400",
                              color: "black",
                            }}
                          >
                            Phase Title
                          </th>
                          <th
                            style={{
                              color: "#616161",
                              fontWeight: "400",
                              color: "black",
                            }}
                          >
                            Planned Hours
                          </th>
                          <th
                            style={{
                              color: "#616161",
                              fontWeight: "400",
                              color: "black",
                            }}
                          >
                            Actual Hours
                          </th>
                          <th
                            style={{
                              color: "#616161",
                              fontWeight: "400",
                              color: "black",
                            }}
                          >
                            Invoice Status
                          </th>
                          {!allUnclaimed && (
                            <>
                              <th style={{ width: "200px" }}>Invoice Number</th>
                              <th>Invoice Date</th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {project.phases.map((phase, index) => (
                          <tr key={phase.id}>
                            {hasPermission("projects", "delete") && (
                              <td>
                                <input
                                  type="checkbox"
                                  name={phase.name}
                                  checked={selectedPhaseId.includes(
                                    phase.phase_id
                                  )}
                                  onChange={(e) =>
                                    handleDeletePhaseClick(e, phase.phase_id)
                                  }
                                />
                              </td>
                            )}
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Link
                                  to={`/project/${projectId}/phase/${phase.phase_id}`}
                                  className="phase-tile"
                                >
                                  {phase.phaseno}
                                </Link>
                                {hasPermission("projects", "update") && (
                                  <button
                                    onClick={() => {
                                      handleEditClick(phase);
                                    }}
                                    className="edit-icon-wrapper"
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "none",
                                      marginRight: "50px",
                                    }}
                                    title="Edit Phase"
                                  >
                                    <EditIcon
                                      style={{
                                        color: "blue",
                                        backgroundColor: "transparent",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </button>
                                )}
                              </div>
                            </td>
                            <td>{phase.title}</td>
                            <td>
                              {phase.planned_hours
                                ? phase.planned_hours
                                : "00:00"}
                            </td>
                            {/* <td>{phase.actualhours}</td>{" "} */}
                            <td>
                              {phase.actualhours
                                ? phase.actualhours.substring(0, 5)
                                : "00:00"}
                            </td>
                            <td
                              style={{
                                backgroundColor:
                                  phase.invoice_status === "Claimed"
                                    ? "#a3d6a7"
                                    : "#d2b48c",
                                color: "white",
                                width: "140px",
                                textAlign: "center",
                                fontWeight: "550",
                              }}
                            >
                              {hasPermission("projects", "create") ? (
                                <select
                                  className="status-dropdown"
                                  value={phase.invoice_status}
                                  onChange={(e) =>
                                    updateClaimStatus(
                                      phase.phase_id,
                                      e.target.value,
                                      "status"
                                    )
                                  }
                                  style={{
                                    border: "none",
                                    backgroundColor:
                                      phase.invoice_status === "Claimed"
                                        ? "#a3d6a7"
                                        : "#d2b48c",
                                    color: "white",
                                    paddingLeft: "38px",
                                    paddingRight: "28px",
                                    fontWeight: "550",
                                  }}
                                >
                                  <option
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                      fontWeight: "550",
                                    }}
                                    value="Unclaimed"
                                  >
                                    Unclaimed
                                  </option>
                                  <option
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                      fontWeight: "550",
                                    }}
                                    value="Claimed"
                                  >
                                    Claimed
                                  </option>
                                </select>
                              ) : (
                                phase.invoice_status
                              )}
                            </td>
                            {!allUnclaimed && (
                              <>
                                <td>
                                  <input
                                    value={phase.invoice_no}
                                    className="invoice-element"
                                    placeholder="Enter Invoice Number"
                                    type="text"
                                    style={{
                                      cursor:
                                        !hasPermission("projects", "update") ||
                                        phase.invoice_status === "Unclaimed"
                                          ? "not-allowed"
                                          : "",
                                    }}
                                    onChange={(e) => {
                                      updateClaimStatus(
                                        phase.phase_id,
                                        e.target.value,
                                        "number"
                                      ); // Update only the date
                                    }}
                                    disabled={
                                      !hasPermission("projects", "update") ||
                                      phase.invoice_status === "Unclaimed"
                                    }
                                  />
                                </td>
                                <td>
                                  <DatePicker
                                    onChange={(date) => {
                                      console.log("Selected date:", date);
                                      updateClaimStatus(
                                        phase.phase_id,
                                        date,
                                        "date"
                                      ); // Update only the date
                                    }}
                                    style={{
                                      cursor:
                                        !hasPermission("projects", "update") ||
                                        phase.invoice_status === "Unclaimed"
                                          ? "not-allowed"
                                          : "",
                                    }}
                                    selected={
                                      phase.invoice_date &&
                                      isValid(new Date(phase.invoice_date))
                                        ? new Date(phase.invoice_date)
                                        : null
                                    }
                                    disabled={
                                      !hasPermission("projects", "update") ||
                                      phase.invoice_status === "Unclaimed"
                                    }
                                    placeholderText="Select Date"
                                    className="invoice-input"
                                    dateFormat="dd-MM-yyyy"
                                  />
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div
                    className={
                      isNavBarOpen
                        ? "phase-hours-summary"
                        : "phase-hours-summary-close"
                    }
                  >
                    <h3
                      className={
                        isNavBarOpen
                          ? "phase-details-text"
                          : "phase-details-text-close"
                      }
                    >
                      Phase Planned Hours Summary : {totalPlannedHours}
                    </h3>
                    <div
                      className={
                        isNavBarOpen
                          ? "phase-pagination"
                          : "phase-pagination-close"
                      }
                    >
                      <h3 className="pagination-total">
                        Total : {project.phases.length}
                      </h3>
                      <button
                        onClick={handleFirstPage}
                        disabled={currentPage === 1}
                        className="phase-arrow-btn"
                      >
                        <MdKeyboardDoubleArrowLeft />
                      </button>
                      <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="phase-arrow-btn"
                      >
                        {/* */}
                        <MdKeyboardArrowLeft />
                      </button>
                      <span
                        style={{
                          marginTop: "-15px",
                          textWrap: "nowrap",
                          backgroundColor: "transparent",
                        }}
                      >
                        Page {currentPage} of {totalPages}
                      </span>
                      <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="phase-arrow-btn"
                      >
                        <MdKeyboardArrowRight />
                      </button>
                      <button
                        onClick={handleLastPage}
                        disabled={currentPage === totalPages}
                        className="phase-arrow-btn"
                      >
                        <MdKeyboardDoubleArrowRight />
                      </button>
                    </div>
                  </div>
                </>
              )}
              {bulkAction && (
                <div className="action-box" ref={profileRef}>
                  {hasPermission("projects", "delete") && (
                    <button
                      onClick={() => setDeleteDialogOpen(true)}
                      title="Delete Phase"
                      className="add-btn"
                      style={{ backgroundColor: "Red" }}
                    >
                      Delete
                    </button>
                  )}
                  {hasPermission("projects", "update") && (
                    <button
                      onClick={() => setClaimStatus((prev) => !prev)}
                      className="add-btn"
                      style={{ textWrap: "nowrap" }}
                    >
                      Claim Status
                    </button>
                  )}
                  {claimStatus && hasPermission("projects", "update") && (
                    <select
                      onChange={(e) =>
                        updateBulkClaimStatus(selectedPhaseId, e.target.value)
                      }
                      className="bulk-select"
                    >
                      <option>Select Claim Status</option>
                      <option>Claimed</option>
                      <option>Unclaimed</option>
                    </select>
                  )}
                </div>
              )}
              {/* Add Phase Dialog */}
              {phaseDialogOpen && (
                <div className="add-variation-dial">
                  <div className="dial-v-header">
                    <h3 className="add-edit-variation">New Phase</h3>
                  </div>

                  <div className="add-phase-content">
                    <h3 className="add-phase-heading">Phase No</h3>
                    <input
                      label="Phase Title"
                      className="phase-input"
                      value={newPhase.phaseno}
                      type="text"
                      onChange={(e) =>
                        setNewPhase({ ...newPhase, phaseno: e.target.value })
                      }
                    />
                    <h3 className="add-phase-heading">Phase Title</h3>
                    <input
                      label="Phase No"
                      className="phase-input"
                      value={newPhase.title}
                      type="text"
                      onChange={(e) =>
                        setNewPhase({ ...newPhase, title: e.target.value })
                      }
                    />
                  </div>
                  <div className="btn-con-second">
                    <button
                      onClick={handleAddPhase}
                      style={{ marginLeft: "26px", width: "50px" }}
                      className="add-phase-dial-btn"
                    >
                      Add
                    </button>
                    <button
                      onClick={handleClosePhaseDialog}
                      className="cancel-btn"
                      style={{ cursor: "pointer" }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Dialog */}
      {deleteDialogOpen && (
        <div className={`delete-phase-dial ${dialBoxopen ? "open" : "close"} `}>
          {" "}
          <div className="dial-d-header">
            <h3 style={{ backgroundColor: "transparent", marginLeft: "15px" }}>
              Delete Phase
            </h3>
          </div>
          <div style={{ backgroundColor: "white" }}>
            <p style={{ backgroundColor: "white" }}>
              Are you sure you want to delete this phase? This action cannot be
              undone.
            </p>
            <input
              type="checkbox"
              id="delete-phase"
              checked={isCheckboxChecked}
              onChange={() => setIsCheckboxChecked((prev) => !prev)}
            />
            <label htmlFor="delete-phase" style={{ backgroundColor: "white" }}>
              I understand this action cannot be undone.
            </label>
          </div>
          <div className="delete-dial-btn-con">
            <button
              onClick={handleDeleteConfirm}
              color="secondary"
              variant="contained"
              disabled={!isCheckboxChecked}
              className="add-btn"
              style={{ backgroundColor: isCheckboxChecked ? "red" : "gray" }}
            >
              Delete
            </button>
            <button onClick={handleCloseDial} className="cancel-btn">
              Cancel
            </button>
          </div>
        </div>
      )}
      {phaseEditDialogOpen && editingPhase && (
        <div className="add-variation-dial">
          <div className="dial-v-header">
            <h3 className="add-edit-variation">Edit Phase Details</h3>
          </div>
          <div className="add-variation-content">
            <h3 className="issue-label">Phase No</h3>
            <input
              type="text"
              className="issue-number"
              name="phaseno"
              value={formValues.phaseno}
              onChange={handleInputChange}
            />

            <h3 className="issue-label">Phase Title</h3>
            <input
              type="text"
              className="issue-number"
              name="title"
              value={formValues.title}
              onChange={handleInputChange}
            />
          </div>
          <div className="edit-btn-con-second">
            <button className="add-edit-issue-btn" onClick={handleSaveClick}>
              Save
            </button>
            <button
              className="cancel-btn"
              style={{ marginRight: "140px" }}
              onClick={() => setPhaseEditDialogOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectDetails;
