import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register necessary Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

// Function to generate random colors (optional)
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const CustomerPieChart = ({ projects }) => {
  // Step 1: Aggregate the projects by customer name
  const customerCounts = projects.reduce((acc, project) => {
    const customer = project.customer_name;
    acc[customer] = (acc[customer] || 0) + 1;
    return acc;
  }, {});

  // Step 2: Prepare data for the Pie chart
  const chartData = {
    labels: Object.keys(customerCounts),
    datasets: [
      {
        data: Object.values(customerCounts),
        backgroundColor: Object.keys(customerCounts).map(() =>
          getRandomColor()
        ), // Generate random colors for each customer
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        enabled: true,
      },
    },
    hover: {
      mode: "nearest",
      animationDuration: 200,
      shadowBlur: 10, // Adds a shadow effect for a lifted appearance
      shadowColor: "rgba(0, 0, 0, 0.3)", // Dark shadow color for a soft glow effect
    },
    animation: {
      duration: 500,
      easing: "easeOutCirc", // Circular easing for a smooth deceleration
      stagger: 500, // Staggers elements to animate one after another
    },
  };

  return (
    <div className="project-status">
      <h2 className="pie-header">Projects by Customer</h2>
      <Pie
        data={chartData}
        options={options}
        style={{ position: "relative", left: "130px" }}
      />
    </div>
  );
};

export default CustomerPieChart;
