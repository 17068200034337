// GroupDetails.js
import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import api from "../api/axiosConfig";
import "../styles/projects/GroupDetails.css";
import { MultiSelect } from "react-multi-select-component";
import { ProjectContext } from "../App";
import { IoMdClose } from "react-icons/io";
import { Helmet } from "react-helmet";

const GroupDetails = () => {
  const { groupId } = useParams();
  const { isNavBarOpen } = useContext(ProjectContext);
  const [group, setGroup] = useState({});
  const [users, setUsers] = useState([]); // This holds the selected users
  const [projects, setProjects] = useState([]); // This holds the selected projects
  const [availableUsers, setAvailableUsers] = useState([]); // To hold all available users
  const [availableProjects, setAvailableProjects] = useState([]); // To hold all available projects
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGroupDetails = async () => {
      try {
        const groupResponse = await api.get(`/api/groups/${groupId}`);
        const usersResponse = await api.get(
          `/api/groups/${groupId}/update-users`
        );
        const projectsResponse = await api.get(
          `/api/groups/${groupId}/update-projects`
        );
        const allUsersResponse = await api.get("/api/users");
        const allProjectsResponse = await api.get("/api/projects1");

        setGroup(groupResponse.data);

        // Set selected users and projects in the format required
        const selectedUsers = usersResponse.data.map((user) => ({
          value: user.id,
          label: user.name,
        }));
        setUsers(selectedUsers);

        const selectedProjects = projectsResponse.data.map((project) => ({
          value: project.id,
          label: project.name,
        }));
        setProjects(selectedProjects);

        // Get IDs of selected users for sorting
        const selectedUserIds = new Set(
          selectedUsers.map((user) => user.value)
        );
        const selectedProjectIds = new Set(
          selectedProjects.map((project) => project.value)
        );
        // Format and sort all users with selected ones first, then alphabetically
        const formattedMembers = allUsersResponse.data
          .filter((member) => member.status !== "Inactive")
          .map((member) => ({
            value: member.id,
            label: member.name,
          }))
          .sort((a, b) => {
            // Prioritize selected users
            const aIsSelected = selectedUserIds.has(a.value);
            const bIsSelected = selectedUserIds.has(b.value);

            if (aIsSelected && !bIsSelected) return -1;
            if (!aIsSelected && bIsSelected) return 1;

            // Sort alphabetically for unselected users
            return a.label.localeCompare(b.label);
          });

        setAvailableUsers(formattedMembers); // Set sorted available users

        // Format and set all projects
        const formattedProjects = allProjectsResponse.data
          .map((project) => ({
            value: project.id,
            label: project.name,
          }))
          .sort((a, b) => {
            const aIsSelected = selectedProjectIds.has(a.value);
            const bIsSelected = selectedProjectIds.has(b.value);

            if (aIsSelected && !bIsSelected) return -1;
            if (!aIsSelected && bIsSelected) return 1;

            return a.label.localeCompare(b.label);
          });
        setAvailableProjects(formattedProjects);
      } catch (error) {
        setError(error);
        console.error("Error fetching group details:", error.message || error);
      } finally {
        setLoading(false);
      }
    };

    fetchGroupDetails();
  }, [groupId]);

  const handleUserSelectionChange = (selectedUsers) => {
    setUsers(selectedUsers); // Update selected users
  };

  const handleProjectSelectionChange = (selectedProjects) => {
    setProjects(selectedProjects); // Update selected projects
  };

  const handleSaveChanges = async () => {
    try {
      const userIds = users.map((user) => user.value);
      const projectIds = projects.map((project) => project.value);
      await api.post(`/api/groups/${groupId}/update-users`, { users: userIds });
      await api.post(`/api/groups/${groupId}/update-projects`, {
        projects: projectIds,
      });

      alert("Group updated successfully!");
    } catch (error) {
      console.error(
        "Error updating group:",
        error.response?.data || error.message
      );
      alert(
        "Error updating group: " +
          (error.response?.data?.error || "Unknown error")
      );
    }
  };
  if (loading)
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  if (error) return <p>Error loading group details</p>;

  return (
    <div
      className={
        isNavBarOpen
          ? "group-details-container"
          : "group-details-container-close"
      }
    >
      <Helmet>
        <title>Msuite - Projects</title>
      </Helmet>
      <Link to="/settings">
        <IoMdClose className="group-exit-arrow" />
      </Link>{" "}
      <h3>
        Group Name :{" "}
        <span style={{ fontWeight: "normal", marginLeft: "30px" }}>
          {group.name} ({group.code})
        </span>
      </h3>
      <h3>
        Description :{" "}
        <span style={{ fontWeight: "normal", marginLeft: "37px" }}>
          {group.description}
        </span>
      </h3>
      <h3>
        Manager:{" "}
        <span style={{ fontWeight: "normal", marginLeft: "62px" }}>
          {group.manager_user_name}
        </span>
      </h3>
      <div className="group-parent">
        <div className="group-users">
          <h3>Add Users to the Group</h3>
          <MultiSelect
            className="multi-select-group"
            options={availableUsers}
            value={users}
            onChange={handleUserSelectionChange}
          />
        </div>
        <div className="group-projects">
          <h3>Add Projects to the Group</h3>
          <MultiSelect
            className="multi-select-group"
            options={availableProjects}
            value={projects} // Mark projects that are already associated with the group
            onChange={handleProjectSelectionChange}
          />
        </div>
      </div>
      <button onClick={handleSaveChanges} className="add-btn save-btn">
        Save Changes
      </button>
      <div className="group-users-table">
        <table className="project-content-table" style={{ width: "30%" }}>
          <thead>
            <tr>
              <th style={{ width: "2px" }}>S.No</th>
              <th>User Name</th>
            </tr>
          </thead>
          <tbody>
            {users.length === 0 ? (
              <tr>
                <td colSpan="2" style={{ textAlign: "center" }}>
                  No users available
                </td>
              </tr>
            ) : (
              users.map((user, index) => (
                <tr key={user.value}>
                  <td style={{ textAlign: "center" }}>{index + 1}</td>
                  <td>{user.label}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="group-table">
        <table
          className="project-content-table"
          style={{ width: "30%", top: "-1px" }}
        >
          <thead>
            <tr>
              <th style={{ width: "2px" }}>S.No</th>
              <th>Project Name</th>
            </tr>
          </thead>
          <tbody>
            {projects.length === 0 ? (
              <tr>
                <td colSpan="2" style={{ textAlign: "center" }}>
                  No projects available
                </td>
              </tr>
            ) : (
              projects.map((project, index) => (
                <tr key={project.value}>
                  <td style={{ textAlign: "center" }}>{index + 1}</td>
                  <td>{project.label}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GroupDetails;
