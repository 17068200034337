import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CustomerBarChart = ({ projects }) => {
  // Step 1: Aggregate the projects by customer name
  const customerCounts = projects.reduce((acc, project) => {
    const customer = project.customer_name;
    acc[customer] = (acc[customer] || 0) + 1;
    return acc;
  }, {});

  // Step 2: Prepare data for the Bar chart
  const chartData = {
    labels: Object.keys(customerCounts), // Customer names
    datasets: [
      {
        label: "Number of Projects",
        data: Object.values(customerCounts), // Count of projects per customer
        backgroundColor: "rgba(75, 192, 192, 0.6)", // Bar color
        borderColor: "rgba(75, 192, 192, 1)", // Border color
        borderWidth: 1,
      },
    ],
  };

  // Step 3: Options to customize the chart appearance (optional)
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Projects by Customer",
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Customers",
        },
        // Rotate labels if necessary to avoid overlap
        ticks: {
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Projects",
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="project-bar-status">
      <h2 className="pie-header">Projects by Customer</h2>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default CustomerBarChart;
