import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register necessary Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const GroupProjectsPieChart = ({ projects }) => {
  // Step 1: Aggregate the count of projects per group
  const groupCounts = projects.reduce((acc, project) => {
    project.associated_groups.forEach((group) => {
      acc[group] = (acc[group] || 0) + 1;
    });
    return acc;
  }, {});
  // Function to generate random colors
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Step 2: Prepare data for the Pie chart
  const chartData = {
    labels: Object.keys(groupCounts), // Group names
    datasets: [
      {
        data: Object.values(groupCounts), // Project counts per group
        backgroundColor: Object.keys(groupCounts).map(() => getRandomColor()),
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        enabled: true,
      },
    },
    hover: {
      mode: "nearest",
      animationDuration: 200,
      shadowBlur: 10, // Adds a shadow effect for a lifted appearance
      shadowColor: "rgba(0, 0, 0, 0.3)", // Dark shadow color for a soft glow effect
    },
    animation: {
      duration: 500,
      easing: "easeOutCirc", // Circular easing for a smooth deceleration
      stagger: 500, // Staggers elements to animate one after another
    },
  };

  // Step 3: Return the Pie chart component
  return (
    <div className="project-status">
      <h2 className="pie-header">Projects by Associated Groups</h2>
      <Pie
        data={chartData}
        options={options}
        style={{ position: "relative", left: "130px" }}
      />
    </div>
  );
};

export default GroupProjectsPieChart;
