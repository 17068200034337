import React, { useState, useEffect, createContext } from "react";
import NProgress from "nprogress";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import AppLayout from "./components/Layouts/AppLayout.js";
import Home from "./components/Home.js";
import CustomerList from "./components/CustomerList";
import ContactList from "./components/ContactList.js";
import Overview from "./components/TimeSheet/Overview.js";
import SignIn from "./components/SignIn/SignIn.js";
import ForgotPassword from "./components/SignIn/ForgotPassword.js";
import ResetPassword from "./components/SignIn/ResetPassword.js";
import ProjectList from "./components/Projects/ProjectList";
import ProjectLayout from "./components/Layouts/ProjectLayout";
import ProjectUsers from "./components/ProfileUser/ProjectUsers";
import { AuthProvider, useAuth } from "./components/Authentication/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import TimeSheetTable from "./components/TimeSheet/TimesheetTable";
import TableTimeSheet from "./components/TimeSheet/TableTimesheet";
import ProjectDetails from "./components/Projects/ProjectDetails";
import PhaseDetails from "./components/Projects/PhaseDetails.js";
import VariationDetails from "./components/TimeSheet/VariationDetails";
import IssueDetails from "./components/TimeSheet/IssueDetails";
import General from "./components/TimeSheet/General";
import GroupList from "./components/GroupList.js";
import GroupDetails from "./components/GroupDetails.js";
import Settings from "./components/Settings/Settings.js";
import DashBoard from "./components/Projects/Dashboard.js";
import UserDetails from "./components/UserDetails/UserDetails.js";
import { Estimation } from "./components/Projects/Estimation.js";
import { RoleProvider } from "./components/Settings/RoleContext";
import "nprogress/nprogress.css";

export const ProjectContext = createContext();

const RouteChangeHandler = () => {
  const location = useLocation();

  useEffect(() => {
    // Start progress bar on route change
    NProgress.start();
    // Complete progress bar once route change is done
    const timeout = setTimeout(() => NProgress.done(), 1000);

    return () => clearTimeout(timeout);
  }, [location]);

  return null;
};

const AppContent = () => {
  const [refresh, setRefresh] = useState(false);
  const [username, setUsername] = useState("");
  const [dialBoxopen, setDialBoxopen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isNavBarOpen, setIsNavBarOpen] = useState(true);
  const [addTimesheetOpen, setAddTimesheetOpen] = useState(false);
  const [addProjectOpen, setAddProjectOpen] = useState(false);
  NProgress.configure({
    showSpinner: false,
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const location = useLocation(); // Detect current route
  const [apiEndpoint, setApiEndpoint] = useState("/home"); // Default API endpoint

  useEffect(() => {
    // Update the API endpoint based on the route
    const endpoint = [location.pathname] || "/home";
    setApiEndpoint(endpoint);

    // Fetch data for the current tab
    const fetchData = async () => {
      setLoading(true);
      NProgress.start(); // Start progress bar
      try {
        const response = await fetch(endpoint); // Fetch API data
        const result = await response.json();
        setData(result); // Update state with fetched data
        NProgress.done(); // Complete progress bar
      } catch (error) {
        console.error("Error fetching data:", error);
        NProgress.done(); // Complete progress bar in case of error
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [location.pathname]);

  const onFrameButtonClick = () => {
    setDialBoxopen((prev) => !prev);
    document.body.classList.remove("no-scroll");
  };

  const { isAuthenticated } = useAuth();

  // Helper function to create protected routes
  const createProtectedRoute = (path, element, requiredRole = null) => (
    <Route
      path={path}
      element={
        <ProtectedRoute requiredRole={requiredRole}>{element}</ProtectedRoute>
      }
    />
  );

  return (
    <ProjectContext.Provider
      value={{
        dialBoxopen,
        setDialBoxopen,
        onFrameButtonClick,
        openDialog,
        setOpenDialog,
        setIsNavBarOpen,
        isNavBarOpen,
        setAddTimesheetOpen,
        addTimesheetOpen,
        setAddProjectOpen,
        addProjectOpen,
      }}
    >
      <div>
        {isAuthenticated && <AppLayout username={username} />}
        <div className="content-container">
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <span className="loading-animation">LOADING...</span>
            </div>
          ) : (
            <Routes>
              <Route path="/" element={<SignIn setUsername={setUsername} />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              {createProtectedRoute("/project/:projectId", <ProjectLayout />)}
              {createProtectedRoute("/home", <Home />)}
              {createProtectedRoute("/estimate", <Estimation />)}
              {createProtectedRoute(
                "/project",
                <ProjectList refresh={refresh} />
              )}
              {createProtectedRoute(
                "/customerlist",
                <CustomerList refresh={refresh} />,
                "Administrator"
              )}
              {createProtectedRoute(
                "/api/customers/:customerId/contacts",
                <ContactList refresh={refresh} />,
                "Administrator"
              )}
              {createProtectedRoute(
                "/grouplist",
                <GroupList refresh={refresh} />,
                "Administrator"
              )}
              {createProtectedRoute(
                "/group/:groupId",
                <GroupDetails refresh={refresh} />,
                "Administrator"
              )}
              {createProtectedRoute("/settings", <Settings />, "Administrator")}
              {createProtectedRoute("/timesheet/overview", <Overview />)}
              {createProtectedRoute(
                "/project/:projectId/users",
                <div>
                  <ProjectLayout />
                  <ProjectUsers />
                </div>
              )}
              {createProtectedRoute(
                "/project/:projectId/timesheets/general",
                <div>
                  <ProjectLayout />
                  <General />
                </div>
              )}
              {createProtectedRoute(
                "/project/:projectId/dashboard",
                <div>
                  <ProjectLayout />
                  <DashBoard />
                </div>
              )}
              {createProtectedRoute(
                "/project/:projectId/timesheetsold",
                <div>
                  <ProjectLayout />
                  <TableTimeSheet refresh={refresh} />
                </div>
              )}
              {createProtectedRoute(
                "/project/:projectId/timesheets",
                <div>
                  <ProjectLayout />
                  <TimeSheetTable refresh={refresh} />
                </div>
              )}
              {createProtectedRoute(
                "/project/phases/:projectId",
                <div>
                  <ProjectLayout />
                  <ProjectDetails />
                </div>
              )}
              {createProtectedRoute(
                "/project/:projectId/phase/:phaseId",
                <div>
                  <ProjectLayout />
                  <PhaseDetails />
                </div>
              )}
              {createProtectedRoute(
                "/project/:projectId/timesheets/variation",
                <div>
                  <ProjectLayout />
                  <VariationDetails />
                </div>
              )}
              {createProtectedRoute(
                "/project/:projectId/timesheets/issue",
                <div>
                  <ProjectLayout />
                  <IssueDetails />
                </div>
              )}
              {createProtectedRoute(
                "/settings/user-details",
                <div>
                  <UserDetails />
                </div>
              )}
              {createProtectedRoute(
                "/user-details/:id",
                <div>
                  <UserDetails />
                </div>
              )}
            </Routes>
          )}
        </div>
      </div>
    </ProjectContext.Provider>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <RoleProvider>
        <Router>
          <RouteChangeHandler />
          <AppContent />
        </Router>
      </RoleProvider>
    </AuthProvider>
  );
};

export default App;
